import { createStore, createHook } from "react-sweet-state";
import { addExceptionApi } from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    loading: true,
    form: {
      personId: "",
      date: "",
      exception: ""
    },
    error: null,
  },
  // actions that trigger store mutation
  actions: {
    updateForm:
      (updateForm) =>
      async ({ setState, getState }) => {
        const replaceForm = { ...getState().form, ...updateForm };

        setState({ form: replaceForm });
    },
    createData:
      (onSuccess) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          await addExceptionApi(getState().form);
          setState({ isLoading: false });
          if (onSuccess) {
            onSuccess();
          }
          return;
        } catch (err) {
          setState({
            error:
              err && err.response ? err.response.data : "Unknow add data error",
          });
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "addExceptionService",
});

export const useAddException = createHook(Store);
