import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useListOfficeStore } from "./stores";
import Page404 from "../../Page404";
import Home from "./Home";
import Detail from "./Detail";
import Add from "./Add";
import Edit from "./Edit";

export default function Shift() {
  const { path } = useRouteMatch();

  const [, actionsListStore] = useListOfficeStore();
  useEffect(() => {
    actionsListStore.loadData();
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route path={`${path}/detail/:id`}>
          <Detail />
        </Route>
        <Route path={`${path}/add`}>
          <Add />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <Edit />
        </Route>
        <Route path={`${path}/*`}>
          <Page404 />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
