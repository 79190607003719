import React, { useEffect, useState } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import {
  useDetailPersonStore,
  useListShiftStore,
  useDetailOrgStore,
} from "./stores";
import { ListTable } from "./components";
import { useParams } from "react-router-dom";

export default function Detail() {
  const { id } = useParams();
  const [keyTable] = useState(0);
  const [stateDetailPerson, actionsDetailPerson] = useDetailPersonStore();
  const [stateShift, actionsStateShift] = useListShiftStore();

  const [, actionDetailOrg] = useDetailOrgStore();

  useEffect(() => {
    document.title = `Berdasarkan Pegawai - Laporan`;
    actionsStateShift.loadData();
    actionDetailOrg.findData(id);

    actionsDetailPerson.updateStore({ limit: 1 });
    actionsDetailPerson.loadData();

    /* actionsDetailPerson.updateStore({ search: stateDetailPerson.data.name });
    actionsDetailPerson.loadData(); */
  }, []);

  // let handleSearch = async (e) => {
  //   e.preventDefault();
  //   await actionsDetailPerson.loadData();
  //   actionsDetailPerson.updateStore({ search: "" });
  // };
  console.log(stateDetailPerson);
  let contentUi = <Loader />;
  if (!stateDetailPerson.isLoading && stateDetailPerson.data) {
    contentUi = (
      <div className="pb-8">
        <ListTable
          key={keyTable}
          data={{
            personId: id,
          }}
          shift={stateShift.data}
          office={id}
        />
      </div>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Daftar Berdasarkan Pegawai"
        childs={[
          { text: "Laporan", link: "" },
          { text: "Berdasarkan Pegawai", link: "/report/employee" },
          {
            text:
              stateDetailPerson.data && !stateDetailPerson.isLoading
                ? stateDetailPerson.data.name
                : "",
            link: "",
          },
        ]}
      />
      <div className="container mx-auto px-6 min-h-screen mb-6">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
