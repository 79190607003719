import { createStore, createHook } from "react-sweet-state";
import { addApi } from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    loading: true,
    form: {
      code: "",
      description: "",
      checkIn: {
        location: "",
        timeRange: [1000, 1000],
      },
      checkOut: {
        location: "",
        timeRange: [1000, 1000],
      },
      offices: [],
    },
    error: null,
  },
  // actions that trigger store mutation
  actions: {
    updateForm:
      (updateForm) =>
      async ({ setState, getState }) => {
        const replaceForm = { ...getState().form, ...updateForm };

        setState({ form: replaceForm });
      },
    createData:
      (onSuccess) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          await addApi(getState().form);
          setState({ isLoading: false });

          if (onSuccess) {
            onSuccess();
          }
          return;
        } catch (err) {
          setState({
            error:
              err && err.response ? err.response.data : "Unknow add data error",
          });
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "createSettingShiftService",
});

export const useCreateStore = createHook(Store);
