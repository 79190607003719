import React, { useState, useEffect } from "react";
import { useListSchedule } from "../stores";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { convertTime, getFirstDay, getLastDay, addDay, today, subMonth, addMonth}  from "../../../App/action";
import { Loader } from "../../../App/components";
import { useTypeExceptionStore } from "./../../../App/stores";

export function ListData(props) {
  const currentDate = props.currentDate;
  let person = props.person;
  const [stateSchedule, actionsSchedule] = useListSchedule();
  const _export = React.useRef(null);
  const [stateTypeException] = useTypeExceptionStore();

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const getData = () => {
    let personData = [];
    person.forEach((itemPerson, personIdx) => {
      personData.push({
         "Nama": itemPerson.name,
         "day0": "LIBUR",
         "day1": "LIBUR",
         "day2": "LIBUR",
         "day3": "LIBUR",
         "day4": "LIBUR",
         "day5": "LIBUR",
         "day6": "LIBUR",
         "day7": "LIBUR",
         "day8": "LIBUR",
         "day9": "LIBUR",
         "day10": "LIBUR",
         "day11": "LIBUR",
         "day12": "LIBUR",
         "day13": "LIBUR",
         "day14": "LIBUR",
         "day15": "LIBUR",
         "day16": "LIBUR",
         "day17": "LIBUR",
         "day18": "LIBUR",
         "day19": "LIBUR",
         "day20": "LIBUR",
         "day21": "LIBUR",
         "day22": "LIBUR",
         "day23": "LIBUR",
         "day24": "LIBUR",
         "day25": "LIBUR",
         "day26": "LIBUR",
         "day27": "LIBUR",
         "day28": "LIBUR",
         "day29": "LIBUR",
         "day30": "LIBUR",
         "status": "LOAD"
       });
       {[
         ...Array(parseInt(getLastDay(currentDate).format("Do"))),
         ].map((x, i) => {
           actionsSchedule.loadData(itemPerson._id, addDay(getFirstDay(currentDate), i).format(
             "YYYYMMDD"
           ), personIdx);
         }
       )}
      })

      actionsSchedule.updateState({listData: personData, exception: stateTypeException});
  };

  useEffect(async () => {
    getData();
  }, []);


  let contentUi = <Loader />;
  if (!stateSchedule.isLoading && stateSchedule.data) {
    contentUi = (
  <ExcelExport data={stateSchedule.data} ref={_export}>
    <Grid data={stateSchedule.data} style={{ height: "420px" }}>
      <GridToolbar>
        <button
          title="Export Excel"
          className="k-button k-primary"
          onClick={excelExport}
        >
          <i className="material-icons">file_download</i>
          Download Excel
        </button>
      </GridToolbar>
      <GridColumn field="Nama" title="Nama" width="350px" />
      {[
        ...Array(parseInt(getLastDay(currentDate).format("Do"))),
        ].map((x, i) => {
          return <GridColumn key={i} field={`day${i}`} title={addDay(getFirstDay(currentDate), i).format(
            "dddd, Do MMMM"
          )} width="150px"/>
        }
      )}
    </Grid>
  </ExcelExport>
    );
  }

  return (
    <>
    {contentUi}
    </>
  );
}
