import React from "react";
import { Breadcrumb } from "../../../App/components";

export function ListHeader({ currentData }) {
  return (
    <>
      <Breadcrumb
        title="Daftar Shift"
        childs={[
          { text: "Pengaturan", link: "" },
          { text: "Shift", link: "/setting/shift" },
          { text: "Daftar Shift", link: "" }
        ]}
        button={{ text: "Tambah", link: "/setting/shift/add", icon: 'add' }}
      />
    </>
  );
}
