import { createStore, createHook } from "react-sweet-state";
import { detailOrgApi } from "../api";

const initState = {
  isLoading: false,
  error: null,
  data: {},

};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    findData:
      (_id) =>
        async ({ setState, getState }) => {
          setState({ isLoading: true, error: null });
          try {
            const resp = await detailOrgApi(_id);
            if (resp.data && resp.data.code === 200) {
              setState({ data: resp.data.data, isLoading: false });
              return true;
            }
          } catch (err) {
            setState({
              error: err && err.response ? err.response.data : "Unknown error",
              isLoading: false,
            });
            return false;
          }
        },
  },
  // optional, mostly used for easy debugging
  name: "listDetailOrgService",
});

export const useDetailOrgStore = createHook(Store);
