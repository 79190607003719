import { createStore, createHook } from "react-sweet-state";
import { listOrgApi } from "../api";

const initState = {
  isLoading: false,
  error: null,
  limit: 10,
  skip: 0,
  search: "",
  data: [],
  total: 0,
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    updateState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },

    loadData:
      () =>
      async ({ setState, getState, actions }) => {
        setState({ isLoading: true, error: null, data: [] });

        let query = { ...getState() };

        console.log(query);
        try {
          const resp = await listOrgApi(query);

          if (resp.data && resp.data.code === 200) {
            setState({
              data: resp.data.data,
              total: resp.data.total,
              isLoading: false,
            });
            return true;
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "listOrgService",
});

export const useListOrgStore = createHook(Store);
