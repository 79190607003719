import { apiClient } from "../../../services";

export const listApi = function ({ limit, skip, search, unitOrgs }) {
  return apiClient.get(
    `/admin/office/list?limit=${limit}&skip=${skip}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};

export const addApi = function ({ name, address, location }) {
  return apiClient.post(`/admin/office/add`, {
    name: name,
    address: address,
    location: location,
  });
};

export const detailApi = function (_id) {
  return apiClient.get(`/admin/office/detail/${_id}`);
};

export const editApi = function (_id, updateFields) {
  return apiClient.post(`/admin/office/edit/${_id}`, updateFields);
};

export const removeApi = function (_id) {
  return apiClient.post(`/admin/office/remove/${_id}`);
};

export const allOrgApi = function () {
  return apiClient.get(`/admin/org/all/main.js`);
};
