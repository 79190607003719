import { createStore, createHook } from "react-sweet-state";
import { detailApi, editApi } from "../api";
import { toast } from "react-toastify";
import Joi from "joi";

const locationTypeRules = Joi.object({
  type: Joi.string().valid("Point").required(),
  coordinates: Joi.array().items(Joi.number().min(0)).length(2).required(),
});

const bodyRules = Joi.object({
  disabled: Joi.boolean(),
  name: Joi.string(),
  address: Joi.string(),
  location: locationTypeRules,
  unitOrgs: Joi.array().items(Joi.string()),
});

const initState = {
  isLoading: false,
  error: null,
  _id: "",
  name: "",
  address: "",
  unitOrgs: [],
  location: {
    type: "Point",
    coordinates: [0.0, 0.0],
  },
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },

    updateState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    findData:
      (_id) =>
      async ({ setState, getState, actions }) => {
        actions.resetState();
        setState({ isLoading: true });

        try {
          const resp = await detailApi(_id);

          if (resp.data && resp.data.code === 200) {
            if (!resp.data.data) {
              const errMsg = "Data not found";
              setState({
                error: errMsg,
              });
              toast.error(errMsg);
              return;
            }
            setState({ ...resp.data.data });
          }
        } catch (err) {
          const errMsg =
            err && err.response ? err.response.data : "Unknown error";
          setState({
            error: errMsg,
          });
          toast.error(errMsg);
        }

        setState({ isLoading: false });
      },
    updateData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });

        const id = getState()._id;
        let bodyForm = { ...getState() };

        //validate and sanitize
        try {
          bodyForm = await bodyRules.validateAsync(bodyForm, {
            stripUnknown: true,
          });
        } catch (err) {
          setState({
            error: err.details[0].message,
            isLoading: false,
          });
          return false;
        }

        try {
          const resp = await editApi(id, bodyForm);

          if (resp.data && resp.data.code === 200) {
            setState({
              isLoading: false,
            });
            return true;
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "editSettingOfficeService",
});

export const useEditStore = createHook(Store);
