import React from "react";
import { Link } from "react-router-dom";
import { useLayout, useMenu } from "../stores";
import { TopNavAvatar } from "./TopNavAvatar";
import { TopNavNotification } from "./TopNavNotification";

export const TopNav = () => {
  const [stateLayout, actionsLayout] = useLayout();
  const [stateMenu, actionsMenu] = useMenu();

  const menuItem = stateMenu.data.map((menu, idx) => {
    if (!menu.childs) {
      return (
        <Link key={idx} to={menu.route}>
          <li className="cursor-pointer h-full flex items-center text-sm racking-normal mr-4">
            <div className="cursor-pointer text-gray-700 hover:text-blue-500 flex items-center">
              <div className="md:w-6 md:h-6 w-5 h-5">
                <span className="text-xl material-icons-outlined">
                  {menu.icon}
                </span>
              </div>
              <p className="text-md">{menu.text}</p>
            </div>
          </li>
        </Link>
      );
    }

    let childUi;

    if (stateMenu.extend === menu.route) {
      childUi = (
        <ul
          key={idx}
          className="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute  top-0"
        >
          {menu.childs.map((child, childIdx) => {
            return (
              <Link to={child.route} key={childIdx}>
                <li
                  key={child.route}
                  className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-blue-700 hover:text-white px-3 font-normal"
                >
                  {child.text}
                </li>
              </Link>
            );
          })}
        </ul>
      );
    }
    return (
      <li
        key={idx}
        onClick={() =>
          actionsMenu.setExtend(
            stateMenu.extend === menu.route ? "" : menu.route
          )
        }
        className="cursor-pointer h-full flex items-center tracking-normal relative mr-4"
      >
        <div className="flex items-center text-gray-700 hover:text-blue-500">
          <div className="cursor-pointer flex items-center">
            <div className="md:w-6 md:h-6 w-5 h-5">
              <span className="text-xl material-icons-outlined">
                {menu.icon}
              </span>
            </div>
            <p className="text-md">{menu.text}</p>
          </div>
          <span className="ml-2 material-icons-outlined text-sm">
            keyboard_arrow_down
          </span>{" "}
        </div>
        {childUi}
      </li>
    );
  });

  return (
    <nav className="w-full mx-auto bg-white shadow relative z-20">
      <div className="justify-between container px-6 h-16 flex items-center lg:items-stretch mx-auto">
        <div className="flex items-center">
          <div className="mr-10 flex items-center">
            <img src="/logo.png" alt="logo" className="w-8" />
            <h3 className="text-base text-gray-800 font-bold tracking-normal leading-tight ml-3 hidden lg:block">
              {stateMenu.mainTitle}
            </h3>
          </div>
          <ul className="pr-32 xl:flex hidden items-center h-full">
            {menuItem}
          </ul>
        </div>
        <div className="h-full xl:flex hidden items-center justify-end">
          <div className="h-full flex items-center">
            <div className="w-32 pr-16 h-full flex items-center justify-end border-r" />
            <div className="w-full h-full flex">
              <TopNavNotification />
              <TopNavAvatar />
            </div>
          </div>
        </div>
        <div className="visible xl:hidden flex items-center">
          <div>
            <div
              className="text-gray-800"
              onClick={() =>
                actionsLayout.setShowMobileMenu(!stateLayout.showMobileMenu)
              }
            >
              <span className="text-3xl material-icons-outlined">menu</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
