import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Page404Index() {
  useEffect(() => {
    document.title = `Home`;
  }, []);

  return (
    <div className="min-h-screen">
      <div className="flex items-center justify-center py-10">
        <div className="w-full md:w-1/2 xl:w-1/3">
          <div className="mx-5 md:mx-10 text-center uppercase">
            <h1 className="text-9xl font-bold">404</h1>
            <h2 className="text-primary mt-5">Page Not Found</h2>
            <h5 className="mt-2">The page you are looking for is not found.</h5>
            <Link
              to="/"
              className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Go Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
