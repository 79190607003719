import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    phone: "",
    error: null,
  },
  actions: {
    setState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    login:
      () =>
      async ({ setState, getState }) => {
        const { phone } = getState();

        if (!phone || phone.trim().length < 9) {
          return setState({ error: "No Handphone minimal 9 angka" });
        }

        let emailPhone = `${phone}`;
        if (emailPhone.startsWith("08")) {
          emailPhone = emailPhone.replace("08", "+628");
        }

        emailPhone = `m${emailPhone.replace("+", "")}@eabsen.com`;

        setState({ isLoading: true, error: null });
        // try {
        //   await fbAuth.signInWithEmailAndPassword(emailPhone, password);
        // } catch (err) {
        //   let errMsg = err.message;
        //   if (err.code === "auth/user-not-found") {
        //     errMsg = "Nomor hp belum terdaftar ";
        //   }

        //   return setState({ error: errMsg, isLoading: false });
        // }
      },
  },
  // optional, mostly used for easy debugging
  name: "forgotPassword",
});

export const useForgotPasswordStore = createHook(Store);
