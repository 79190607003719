import React, { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useEditStore, useDeleteStore, useListStore } from "../stores";
import { toast } from "react-toastify";
import { Loader } from "../../../App/components";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { convertTime, convertLocation } from "../../../App/action";

export function ListTable({ data }) {
  const [, actionsEditShift] = useEditStore();
  const [stateDelete, actionsDelete] = useDeleteStore();
  const [, actionsList] = useListStore();

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const cancelButtonRef = useRef(null);

  let handleDelete = async (item) => {
    setSelectedItem(item);
    if(item.disabled) {
      actionsEditShift.updateForm({
        _id: item._id,
        code: item.code,
        description: item.description,
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        disabled: false
      });
      actionsEditShift.updateData(() => {
        toast.success(`Berhasil mengubah data "${item.code}"`);
        actionsList.loadData();
      });
    } else {
      setOpen(true);
    }
  };

  let deleteItem = async () => {
    setOpen(false);
    actionsDelete.deleteData({ _id: selectedItem._id }, () => {
      toast.warning(`Menyembunyikan data "${selectedItem.code}"`);
      actionsList.loadData();
    });
  };

  let loadingUi;
  if (stateDelete.isLoading) {
    loadingUi = <Loader />;
  }

  let deleteUi = (
    <Transition.Root show={open} as={Fragment} item={selectedItem}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Menyembunyikan Data
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Anda yakin menyembunyikan data ini? Data yang disembunyikan tidak akan ditampilkan di pilihan Shift.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => deleteItem()}
                >
                  Sembunyikan
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Batal
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );

  const listItems = data.map((item, idx) => {
    return (
      <tr key={idx}>
        <td className="px-6 py-3 whitespace-nowrap">
          <div className="flex items-center">
            <Link
              to={"/setting/shift/detail/" + item._id}
              className="text-sm font-medium text-gray-900"
            >
              {item.code}
            </Link>
          </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap">
          <div className="flex items-center">
            <div className="text-sm text-gray-900">
              {convertLocation(item.checkIn.location)}{" "}
              {convertTime(item.checkIn.timeRange[0])}-
              {convertTime(item.checkIn.timeRange[1])}
            </div>
          </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {convertLocation(item.checkOut.location)}{" "}
            {convertTime(item.checkIn.timeRange[0])}-
            {convertTime(item.checkOut.timeRange[1])}
          </div>
        </td>
        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
         <div className="flex justify-end mt-2 right-0">
            <button
              onClick={() => handleDelete(item)}
              type="button"
              className="flex text-sm text-blue-700 mr-4"
              title={item.disabled ? 'Tampilkan': 'Sembunyikan'}
            >
              <span className="material-icons-outlined">{item.disabled ? 'visibility': 'visibility_off'}</span>
            </button>
            <Link
            to={`/setting/shift/edit/${item._id}`}
              className="flex text-sm text-blue-700"
              title="Ubah"
            >
              <span className="material-icons-outlined">edit</span>
            </Link>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      {loadingUi}
      {deleteUi}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Kode
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Lokasi Absen Masuk
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Lokasi Absen Pulang
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Ubah</span>
                      <span className="sr-only">Hapus</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {listItems}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
