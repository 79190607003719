import React, { useState, useEffect } from "react";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { convertTime, getFirstDay, getLastDay, addDay, today, subMonth, addMonth}  from "../../../App/action";
import { ListData } from "./index";

export function ListTable(props) {
  const [visible, setVisible] = useState(false);
  const [listKey, setListKey] = useState(0);
  const [currentDate, setCurrentDate] = useState(today);
  let person = [];
  let shift = [];

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleChangeDate = (date) => {
    setCurrentDate(date);
    setVisible(false);
    setListKey(listKey + 1);
  };

  useEffect(() => {
    setCurrentDate(today);
  }, []);


  if (props.data.personId !== "") {
    person = props.data;
  }

  if (props.shift.personId !== "") {
    shift = props.shift;
  }

  const datePicker = (
    <div>
      {visible && (
        <Dialog title={"Pilih Tanggal"} onClose={toggleDialog}>
          <Calendar
            focusedDate={new Date()}
            onChange={(event) =>
              handleChangeDate(
                "" +
                  event.value.getFullYear() +
                  ("0" + (event.value.getMonth() + 1)).slice(-2) +
                  ("0" + (event.value.getDate() + 1)).slice(-2)
              )
            }
          />
        </Dialog>
      )}
    </div>
  );

  return (
    <>
      <div className="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg text-xs py-2 px-6">
        <b>Keterangan :</b>
        <table className="w-full mt-1">
          <tbody>
          {shift.map((i, x) => (
            <tr key={x}>
              <td className="w-10">
                <b>{i.code}</b>
              </td>
              <td className="w-5 flex"> : </td>
              <td className="w-full">
                [{convertTime(i.checkIn.timeRange[0])} -{" "}
                {convertTime(i.checkIn.timeRange[1])}] - [
                {convertTime(i.checkOut.timeRange[0])} -{" "}
                {convertTime(i.checkOut.timeRange[1])}]{" "}
                <span>{i.description}</span>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      {datePicker}
      <div className="flex items-center justify-between py-2 px-6">
        <div></div>
        <div className="border rounded-lg px-1 flex items-center">
          <button
            type="button"
            className="cursor-pointer leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center"
            onClick={() => handleChangeDate(subMonth(currentDate, 1))}
          >
            <svg
              className="h-6 w-6 text-gray-500 inline-flex leading-none"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <div className="border-r inline-flex h-6"></div>
          <div
            className="cursor-pointer ml-2 mr-2 text-sm font-bold text-gray-800"
            onClick={toggleDialog}
            title="Pilih Tanggal"
          >
            {getFirstDay(currentDate).format("MMMM YYYY")}
          </div>
          <div className="border-r inline-flex h-6"></div>
          <button
            type="button"
            className="cursor-pointer leading-none rounded-lg transition ease-in-out duration-100 inline-flex items-center cursor-pointer hover:bg-gray-200 p-1"
            onClick={() => handleChangeDate(addMonth(currentDate, 1))}
          >
            <svg
              className="h-6 w-6 text-gray-500 inline-flex leading-none"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg">
            {/* <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50 text-xs">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    rowSpan="3"
                  >
                    Nama
                  </th>
                  {[
                    ...Array(parseInt(getLastDay(currentDate).format("Do"))),
                  ].map((x, i) => (
                    <th key={i} className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {parseInt(
                        getSunday(addDay(getFirstDay(currentDate), i)).format(
                          "Do"
                        )
                      ) ===
                      i + 1 ? (
                        <span className="text-red-600">
                          {addDay(getFirstDay(currentDate), i).format(
                            "dddd, Do MMMM"
                          )}
                        </span>
                      ) : (
                        addDay(getFirstDay(currentDate), i).format(
                          "dddd, Do MMMM"
                        )
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                <PersonItem
                  key={listKey}
                  person={person}
                  currentDate={currentDate}
                /> 
              </tbody>
            </table> */}
            <ListData
              key={listKey}
              person={person}
              currentDate={currentDate}
              update={
                () => {
                  setListKey(listKey + 1);
                }
              }
            /> 
          </div>
        </div>
      </div>
    </>
  );
}
