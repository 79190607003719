import React from "react";
import { Link } from "react-router-dom";

export function Breadcrumb({ title, childs, button = "" }) {
  return (
    <>
      <div className="mt-4 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between">
        <nav className="text-black font-bold text-xs" aria-label="Breadcrumb">
          <ol className="list-none p-0 inline-flex">
            {childs.map((child, childIdx) => {
              return (
                <div key={childIdx}>
                  {childs.length - 1 !== childIdx ? (
                    <li className="flex items-center">
                      {child.link !== "" ? (
                        <Link to={child.link}>{child.text}</Link>
                      ) : (
                        <button className="text-black font-bold text-xs">
                          {child.text}
                        </button>
                      )}
                      <svg
                        className="fill-current w-3 h-3 mx-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                      </svg>
                    </li>
                  ) : (
                    <li>
                      <button
                        className="font-bold text-xs text-gray-500"
                        aria-current="page"
                      >
                        {child.text}
                      </button>
                    </li>
                  )}
                </div>
              );
            })}
          </ol>
        </nav>
      </div>

      <div className="mb-2 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between">
        <div>
          <h4 className="text-2xl font-bold leading-tight text-gray-800">
            {title}
          </h4>
        </div>
        {button !== "" ? (
          <div className="mt-6 lg:mt-0">
            <Link
              to={button.link}
              onClick={button.click}
              className="flex items-center text-base text-blue-700 mr-4"
            >
              <span className="material-icons-outlined">{button.icon}</span>
              <span className="ml-1 font-bold">{button.text}</span>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
