import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import { useDetailStore, useListOrgStore } from "./stores";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

export default function Edit() {
  const { id } = useParams();
  const [state, actions] = useDetailStore();
  const [listOrg] = useListOrgStore();

  useEffect(() => {
    document.title = `Detail Kantor - Pengaturan`;
    actions.findData(id);
  }, []);

  let contentUi = <Loader />;

  if (!state.isLoading) {
    contentUi = (
      <React.Fragment>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-2 sm:px-2">
            <h2
              id="applicant-information-title"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Detail Kantor
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Kantor</dt>
                <dd className="mt-1 text-sm text-gray-900">{state.name}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Alamat</dt>
                <dd className="mt-1 text-sm text-gray-900">{state.address}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Lokasi</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/place/${state.location.coordinates[0]},${state.location.coordinates[1]}`}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Lihat di Peta
                  </a>
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Unit Organinasi
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {state.unitOrgs.map((o, idx) => (
                    <span key={o} className="mr-1">
                      {listOrg.dataObject[o].orgName}
                    </span>
                  ))}
                </dd>
              </div>
            </dl>
          </div>
          <div className="bg-gray-50 px-4 py-2 sm:px-2">
            <div className="flex items-center justify-end space-x-4">
              <Link
                to={`/setting/office`}
                type="button"
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                Kembali
              </Link>
              <Link
                to={`/setting/office/edit/${state._id}`}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Ubah
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  if (state.error) {
    contentUi = <Redirect to="/setting/office" />;
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Detail Kantor"
        childs={[
          { text: "Pengaturan", link: "" },
          { text: "Kantor", link: "/setting/office" },
          { text: "Detail Kantor", link: "" }
        ]}
      />
      <div className="container mx-auto px-6 min-h-screen">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
