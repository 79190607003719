import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader } from "../../App/components";
import { ListHeader, ListTable, ListPaging } from "./components";
import { useListStore } from "./stores";
/* import { Link } from "react-router-dom"; */
/* import { usePageSettingHeaderStore } from "../commons/stores"; */

export default function Home() {
  const [state, actions] = useListStore();
  /* const [statePageSettingHeader] = usePageSettingHeaderStore(); */

  useEffect(() => {
    document.title = `Shift - Pengaturan`;
    actions.loadData();
  }, []);

  let contentUi = <Loader />;
  if (!state.isLoading) {
    contentUi = (
      <React.Fragment>
        <ListTable data={state.data} />
        <ListPaging />
      </React.Fragment>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <ListHeader />
      <div className="container mx-auto px-6 min-h-screen">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
