import { createStore, createHook } from "react-sweet-state";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import "moment/locale/id";
import { convertTime, getFirstDay, getLastDay, addDay, today, subMonth, addMonth}  from "../../../App/action";
import { getScheduleApi } from "../api";

const initState = {
  isLoading: false,
  error: null,
  limit: 10,
  skip: 0,
  listData: [],
  data: [],
  exception: {},
  total: 0,
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    updateState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },

    loadData:
      (personId, date, idx) =>
      async ({ setState, getState, actions }) => {
        setState({ isLoading: true, error: null, listData: [], data: [] });
        try {
            const resp = await getScheduleApi(personId, date);

            if (resp.data && resp.data.code === 200) {
              if (!resp.data) {
                const errMsg = "Data not found";
                toast.error(errMsg);
              }
        
              let newList = getState().listData;
        
              // Set Value
              if(newList[idx] !== null && newList[idx]) {
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 0).format("YYYYMMDD"))) {
                  newList[idx].day0 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 1).format("YYYYMMDD"))) {
                  newList[idx].day1 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
                
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 2).format("YYYYMMDD"))) {
                  newList[idx].day2 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 3).format("YYYYMMDD"))) {
                  newList[idx].day3 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 4).format("YYYYMMDD"))) {
                  newList[idx].day4 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 5).format("YYYYMMDD"))) {
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 6).format("YYYYMMDD"))) {
                  newList[idx].day6 = resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 7).format("YYYYMMDD"))) {
                  newList[idx].day7 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 8).format("YYYYMMDD"))) {
                  if(resp.data.data.exception)  {
                    console.log(resp.data.data.checkIn);
                  }
                  newList[idx].day8 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 9).format("YYYYMMDD"))) {
                  newList[idx].day9 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 10).format("YYYYMMDD"))) {
                  newList[idx].day10 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 11).format("YYYYMMDD"))) {
                  newList[idx].day11 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 12).format("YYYYMMDD"))) {
                  newList[idx].day12 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 13).format("YYYYMMDD"))) {
                  newList[idx].day13 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 14).format("YYYYMMDD"))) {
                  newList[idx].day14 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 15).format("YYYYMMDD"))) {
                  newList[idx].day15 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 16).format("YYYYMMDD"))) {
                  newList[idx].day16 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 17).format("YYYYMMDD"))) {
                  newList[idx].day17 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 18).format("YYYYMMDD"))) {
                  newList[idx].day18 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 19).format("YYYYMMDD"))) {
                  newList[idx].day19 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 20).format("YYYYMMDD"))) {
                  newList[idx].day20 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 21).format("YYYYMMDD"))) {
                  newList[idx].day21 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 22).format("YYYYMMDD"))) {
                  newList[idx].day22 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 23).format("YYYYMMDD"))) {
                  newList[idx].day23 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 24).format("YYYYMMDD"))) {
                  newList[idx].day24 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 25).format("YYYYMMDD"))) {
                  newList[idx].day25 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 26).format("YYYYMMDD"))) {
                  newList[idx].day26 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 27).format("YYYYMMDD"))) {
                  newList[idx].day27 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 28).format("YYYYMMDD"))) {
                  newList[idx].day28 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 29).format("YYYYMMDD"))) {
                  newList[idx].day29 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }
        
                 if(resp.data.data && resp.data.data.date === parseInt(addDay(getFirstDay(date), 30).format("YYYYMMDD"))) {
                  newList[idx].day30 = 
                  newList[idx].day5 =  resp.data.data.exception ? getState().exception[resp.data.data.exception] : resp.data.data.checkIn ? `(${moment(_.sortBy(resp.data.data.checkIn, o => o.dateTime)[0].dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")}) - (${moment(_.last(_.sortBy(resp.data.data.checkIn, o => o.dateTime)).dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss")})` : '-';
                }

                newList[idx].status = "OK";
        
                setState({
                  data: newList,
                  total: newList.length,
                  isLoading: false,
                });
                
              }

              console.log(getState().data);

              return true;  
            }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "listScheduleService",
});

export const useListSchedule = createHook(Store);
