import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    mainTitle: "Admin Pegawai",
    data: [
      { text: "Beranda", route: "/home", icon: "home" },
      { text: "Pegawai",
        route: "/person",
        icon: "groups",
        childs: [
          { text: "Kantor", route: "/person/office" },
          /* { text: "Organisasi", route: "/person/org" }, */
          { text: "Jadwal & Izin", route: "/person/schedule" }
        ],
      },
      /* { text: "Org & Jabatan", route: "/org", icon: "account_tree" }, */
      {
        text: "Laporan",
        route: "/report",
        icon: "summarize",
        childs: [
          { text: "Transaksi", route: "/report/transaction" },
          { text: "Berdasarkan Pegawai", route: "/report/employee" },
        ],
      },
      {
        text: "Pengaturan",
        route: "/setting",
        icon: "settings",
        childs: [
          { text: "Kantor", route: "/setting/office" },
          { text: "Shift", route: "/setting/shift" },
          /* { text: "Coming Soon", route: "/deliverables/components" }, */
        ],
      },
    ],
    extend: "",
  },
  actions: {
    setExtend:
      (newVal) =>
      async ({ setState, getState }) => {
        setState({ extend: newVal });
      },
  },
  // optional, mostly used for easy debugging
  name: "menu",
});

export const useMenu = createHook(Store);
