import React from "react";
import { useListOrgStore, useListStore } from "../stores";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export function ListHeader(props) {
  const [stateListOrg] = useListOrgStore();
  const [stateList, actionsList] = useListStore();
  const [unitOrgsOptions, setUnitOrgsOptions] = React.useState(
    stateListOrg.data
  );

  const filterChangeUnitOrgOptions = (event) => {
    setUnitOrgsOptions(filterBy(stateListOrg.data, event.filter));
  };

  return (
    <div className="w-full pt-4 pb-4">
      <div className="">
        <div className="">Filter Unit Organisasi :</div>
        <div className="w-full">
          <MultiSelect
            filterable={true}
            onFilterChange={filterChangeUnitOrgOptions}
            data={unitOrgsOptions}
            onChange={(e) => {
              actionsList.updateState({
                unitOrgs: e.value.map((o) => {
                  return o._id;
                }),
                skip: 0,
              });
              actionsList.loadData();
            }}
            value={stateList.unitOrgs.map((item) => {
              return stateListOrg.dataObject[item];
            })}
            textField="orgName"
            dataItemKey="_id"
          />
        </div>
      </div>
    </div>
  );
}
