import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page404 from "../../Page404";
import Home from "./Home";
import Detail from "./Detail";

export default function Transaction() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Home />
      </Route>
      <Route path={`${path}/show/:id`}>
        <Detail />
      </Route>
      <Route path={`${path}/*`}>
        <Page404 />
      </Route>
    </Switch>
  );
}
