import { apiClient } from "../../../services";

export const listPersonApi = function ({ limit, search, unitOrgs }) {
  return apiClient.get(
    `/admin/person/list?limit=${limit}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};

export const listShiftApi = function ({ limit, skip, search }) {
  return apiClient.get(
    `/admin/shift/list?limit=1000&skip=${skip}&search=${search}&disabled=false`
  );
};

export const listOrgApi = function ({ limit, skip, search }) {
  return apiClient.get(`/admin/org/main/list?limit=${limit}&skip=${skip}&search=${search}`);
};

export const detailOrgApi = function (_id) {
  return apiClient.get(`/admin/org/detail/${_id}`);
};

export const getScheduleApi = function ({ personId, date }) {
  return apiClient.get(`/admin/att-daily/person/detail/${personId}/${date}`);
};

export const addScheduleApi = function ({ personId, date, shiftId }) {
  return apiClient.post(`/admin/att-daily/person/shift/update/${personId}/${date}`, {
    shiftId: shiftId,
  });
};

export const addExceptionApi = function ({ personId, date, exception }) {
  return apiClient.post(`/admin/att-daily/person/exception/update/${personId}/${date}`, {
    exception: exception,
  });
};

export const updateSchedulesApi = function ({ shiftId, date, personIds }) {
  return apiClient.post(`/admin/att-daily/person/shift/batch/update/${date}`, {
    shiftId: shiftId,
	  personIds: personIds
  });
};

export const updateExceptionsApi = function ({ exception, date, personIds }) {
  return apiClient.post(`/admin/att-daily/person/exception/batch/update/${date}`, {
    exception: exception,
	  personIds: personIds
  });
};

export const removeScheduleApi = function ({ personId, date, shift }) {
  return apiClient.post(`/admin/att-daily/person/shift/remove/${personId}/${date}`);
};

export const removeSchedulesApi = function ({ date, personIds }) {
  return apiClient.post(`/admin/att-daily/person/shift/batch/remove/${date}`, {
	  personIds: personIds
  });
};

export const removeExceptionApi = function ({ personId, date, exception }) {
  return apiClient.post(`/admin/att-daily/person/exception/remove/${personId}/${date}`);
};

export const removeExceptionsApi = function ({ date, personIds }) {
  return apiClient.post(`/admin/att-daily/person/exception/batch/remove/${date}`, {
	  personIds: personIds
  });
};

export const listApi = function ({ limit, skip, search, unitOrgs }) {
  return apiClient.get(
    `/admin/office/list?limit=${limit}&skip=${skip}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};

/* 
export const listOfficePersonApi = function ({ limit, skip, search, officeId }) {
  return apiClient.get(
    `/admin/office-person/office/${officeId}/list?limit=${limit}&skip=${skip}&search=${search}`
  );
};

export const addOfficePersonApi = function ({ officeId, personId }) {
  return apiClient.post(`/admin/office-person/office/${officeId}/person/add`, {
    personId: personId,
  });
};

export const removeOfficePersonApi = function ({ officeId, personId }) {
  console.log(personId);
  return apiClient.post(`/admin/office-person/office/${officeId}/person/remove`, {
    personId: personId,
  });
}; */