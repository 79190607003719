import React, { useState, useEffect } from "react";
import { useCreateStore } from "../stores";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Breadcrumb } from "../../../App/components";
import TimeField from "react-simple-timefield";
import { convertTime } from "../../../App/action";
import { useListOfficeStore } from "../stores";

export function FormBoxAdd() {
  const [codeError, setCodeError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [state] = useCreateStore();

  const history = useHistory();

  const [, actionsOffice] = useListOfficeStore();
  const [stateCreateShift, actionsCreateShift] = useCreateStore();

  useEffect(() => {
    actionsOffice.loadData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValidation = () => {
      if (stateCreateShift.form.code === "") {
        setCodeError("Kode tidak boleh kosong");
        return false;
      } else {
        setCodeError(null);
      }

      if (stateCreateShift.form.code.length >= 8) {
        setCodeError("Kode tidak boleh lebih dari 8 karakter");
        return false;
      } else {
        setCodeError(null);
      }

      if (stateCreateShift.form.description === "") {
        setDescriptionError("Deskripsi tidak boleh kosong");
        return false;
      } else {
        setDescriptionError(null);
      }

      if (stateCreateShift.form.description.length >= 250) {
        setDescriptionError("Deskripsi tidak boleh lebih dari 250 karakter");
        return false;
      } else {
        setDescriptionError(null);
      }

      return true;
    };
    if (formValidation()) {
      actionsCreateShift.createData(() => {
        actionsCreateShift.updateForm({
          code: "",
          description: "",
          checkIn: {
            location: "",
            timeRange: [1000, 1000],
          },
          checkOut: {
            location: "",
            timeRange: [1000, 1000],
          },
          offices: [],
        });
        toast.success("Berhasil menambah data");
        history.push("/setting/shift");
      });
    }
  };

  let errorUi;

  if (state.error) {
    errorUi = (
      <div className="mt-5">
        <Alert type="error" body={state.error} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb
        title="Tambah Shift"
        childs={[
          { text: "Pengaturan", link: "" },
          { text: "Shift", link: "/setting/shift" },
          { text: "Tambah Shift", link: "" },
        ]}
      />
      <form
        className="mb-2 container px-6 mx-auto items-start lg:items-center justify-between"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Kode
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                codeError !== null ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-kode"
              type="text"
              placeholder="Kode"
              value={stateCreateShift.form.code}
              onChange={(event) =>
                actionsCreateShift.updateForm({ code: event.target.value })
              }
            />
            {codeError !== null && (
              <p className="text-red-500 text-xs italic">{codeError}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Deskripsi
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                descriptionError !== null ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-description"
              type="text"
              placeholder="Deskripsi"
              value={stateCreateShift.form.description}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  description: event.target.value,
                })
              }
            />
            {descriptionError !== null && (
              <p className="text-red-500 text-xs italic">{descriptionError}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Lokasi Masuk
            </label>
            <select
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-inoffice"
              value={stateCreateShift.form.checkIn.location}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  checkIn: {
                    ...stateCreateShift.form.checkIn,
                    location: event.target.value,
                  },
                })
              }
            >
              <option value="Office">KANTOR</option>
              <option value="Anywhere">RUMAH</option>
            </select>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Lokasi Pulang
            </label>
            <select
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-outoffice"
              value={stateCreateShift.form.checkOut.location}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  checkOut: {
                    ...stateCreateShift.form.checkOut,
                    location: event.target.value,
                  },
                })
              }
            >
              <option value="Office">KANTOR</option>
              <option value="Anywhere">RUMAH</option>
            </select>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Datang Mulai
            </label>
            <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimestart"
              value={convertTime(stateCreateShift.form.checkIn.timeRange[0])}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  checkIn: {
                    ...stateCreateShift.form.checkIn,
                    timeRange: [
                      parseInt(event.target.value.replace(":", ""), 10),
                      stateCreateShift.form.checkIn.timeRange[1],
                    ],
                  },
                })
              }
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Datang Selesai
            </label>
            <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimend"
              value={convertTime(stateCreateShift.form.checkIn.timeRange[1])}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  checkIn: {
                    ...stateCreateShift.form.checkIn,
                    timeRange: [
                      stateCreateShift.form.checkIn.timeRange[0],
                      parseInt(event.target.value.replace(":", ""), 10),
                    ],
                  },
                })
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Pulang Mulai
            </label>
            <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-endtimestart"
              value={convertTime(stateCreateShift.form.checkOut.timeRange[0])}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  checkOut: {
                    ...stateCreateShift.form.checkOut,
                    timeRange: [
                      parseInt(event.target.value.replace(":", ""), 10),
                      stateCreateShift.form.checkOut.timeRange[1],
                    ],
                  },
                })
              }
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Pulang Selesai
            </label>
            <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-endtimeend"
              value={convertTime(stateCreateShift.form.checkOut.timeRange[1])}
              onChange={(event) =>
                actionsCreateShift.updateForm({
                  checkOut: {
                    ...stateCreateShift.form.checkOut,
                    timeRange: [
                      stateCreateShift.form.checkOut.timeRange[0],
                      parseInt(event.target.value.replace(":", ""), 10),
                    ],
                  },
                })
              }
            />
          </div>
        </div>

        {errorUi}
        <button className="w-full bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
          Tambah
        </button>
      </form>
    </React.Fragment>
  );
}
