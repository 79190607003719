import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Alert, Breadcrumb } from "../../../App/components";
import Select from "react-select";
import makeAnimated from "react-select/animated";
/* import TimeField from "react-simple-timefield"; */
import { convertTime } from "../../../App/action";
import { useListOfficeStore, useEditStore } from "../stores";

const animatedComponents = makeAnimated();

export function FormBoxEdit({ currentData }) {
  const [stateEditShift, actionsEditShift] = useEditStore();
  const [stateOffice] = useListOfficeStore();
  const [codeError, setCodeError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValidation = () => {
      if (stateEditShift.form.code === "") {
        setCodeError("Kode tidak boleh kosong");
        return false;
      } else {
        setCodeError(null);
      }

      if (stateEditShift.form.code.length >= 8) {
        setCodeError("Kode tidak boleh lebih dari 8 karakter");
        return false;
      } else {
        setCodeError(null);
      }

      if (stateEditShift.form.description === "") {
        setDescriptionError("Deskripsi tidak boleh kosong");
        return false;
      } else {
        setDescriptionError(null);
      }

      if (stateEditShift.form.description.length >= 250) {
        setDescriptionError("Deskripsi tidak boleh lebih dari 250 karakter");
        return false;
      } else {
        setDescriptionError(null);
      }

      return true;
    };
    if (formValidation()) {
      actionsEditShift.updateData(() => {
        actionsEditShift.updateForm({
          code: "",
          description: "",
          checkIn: {
            location: "",
            timeRange: [1000, 1000],
          },
          checkOut: {
            location: "",
            timeRange: [1000, 1000],
          },
          offices: [],
        });
        toast.success("Berhasil mengubah data");
        history.push("/setting/shift");
      });
    }
  };

  const options = [];
  let defaultValue = [];

  stateOffice.data.forEach((item) => {
    return options.push({ value: item._id, label: item.name });
  });

  stateOffice.data.forEach((item) => {
    stateEditShift.form.offices.forEach((selectedItem) => {
      if (selectedItem === item._id) {
        // compare option with selected _id
        return defaultValue.push({ value: item._id, label: item.name });
      }
    });
  });

  let errorUi;

  if (stateEditShift.error) {
    errorUi = (
      <div className="mt-5">
        <Alert type="error" body={stateEditShift.error} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb
        title="Ubah Shift"
        childs={[
          { text: "Pengaturan", link: "" },
          { text: "Shift", link: "/setting/shift" },
          { text: "Ubah Shift", link: "" },
        ]}
      />
      <form
        className="mb-2 container px-6 mx-auto items-start lg:items-center justify-between"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Kode
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                codeError !== null ? "border-red-500" : "border-gray-200"
              } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-kode"
              type="readonly"
              placeholder="Kode"
              value={stateEditShift.form.code}
              /* onChange={(event) =>
                actionsEditShift.updateForm({ code: event.target.value })
              } */
            />
            {codeError !== null && (
              <p className="text-red-500 text-xs italic">{codeError}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Deskripsi
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                descriptionError !== null ? "border-red-500" : "border-gray-200"
              } rrounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="grid-description"
              type="readonly"
              placeholder="Deskripsi"
              value={stateEditShift.form.description}
              /* onChange={(event) =>
                actionsEditShift.updateForm({
                  description: event.target.value,
                })
              } */
            />
            {descriptionError !== null && (
              <p className="text-red-500 text-xs italic">{descriptionError}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Lokasi Masuk
            </label>
            <select
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-inoffice"
              value={stateEditShift.form.checkIn.location}
              /* onChange={(event) =>
                actionsEditShift.updateForm({
                  checkIn: {
                    ...stateEditShift.form.checkIn,
                    location: event.target.value,
                  },
                })
              } */
            >
              <option value="Office">KANTOR</option>
              <option value="Anywhere">RUMAH</option>
            </select>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Lokasi Pulang
            </label>
            <select
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-outoffice"
              value={stateEditShift.form.checkOut.location}
              /* onChange={(event) =>
                actionsEditShift.updateForm({
                  checkOut: {
                    ...stateEditShift.form.checkOut,
                    location: event.target.value,
                  },
                })
              } */
            >
              <option value="Office">KANTOR</option>
              <option value="Anywhere">RUMAH</option>
            </select>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Datang Mulai
            </label>
            {/*             <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimestart"
              value={convertTime(stateEditShift.form.checkIn.timeRange[0])}
              onChange={(event) =>
                actionsEditShift.updateForm({
                  checkIn: {
                    ...stateEditShift.form.checkIn,
                    timeRange: [
                      parseInt(event.target.value.replace(":", ""), 10),
                      stateEditShift.form.checkIn.timeRange[1],
                    ],
                  },
                })
              }
            /> */}
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimestart"
              type="readonly"
              value={convertTime(stateEditShift.form.checkIn.timeRange[0])}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Datang Selesai
            </label>
            {/* <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimend"
              value={convertTime(stateEditShift.form.checkIn.timeRange[1])}
              onChange={(event) =>
                actionsEditShift.updateForm({
                  checkIn: {
                    ...stateEditShift.form.checkIn,
                    timeRange: [
                      stateEditShift.form.checkIn.timeRange[0],
                      parseInt(event.target.value.replace(":", ""), 10),
                    ],
                  },
                })
              }
            /> */}
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimend"
              type="readonly"
              value={convertTime(stateEditShift.form.checkIn.timeRange[1])}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Pulang Mulai
            </label>
            {/* <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-endtimestart"
              value={convertTime(stateEditShift.form.checkOut.timeRange[0])}
              onChange={(event) =>
                actionsEditShift.updateForm({
                  checkOut: {
                    ...stateEditShift.form.checkOut,
                    timeRange: [
                      parseInt(event.target.value.replace(":", ""), 10),
                      stateEditShift.form.checkOut.timeRange[1],
                    ],
                  },
                })
              }
            /> */}
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-intimestart"
              type="readonly"
              value={convertTime(stateEditShift.form.checkIn.timeRange[0])}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Jam Pulang Selesai
            </label>
            {/* <TimeField
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-endtimeend"
              value={convertTime(stateEditShift.form.checkOut.timeRange[1])}
              onChange={(event) =>
                actionsEditShift.updateForm({
                  checkOut: {
                    ...stateEditShift.form.checkOut,
                    timeRange: [
                      stateEditShift.form.checkOut.timeRange[0],
                      parseInt(event.target.value.replace(":", ""), 10),
                    ],
                  },
                })
              }
            /> */}
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-endtimeend"
              type="readonly"
              value={convertTime(stateEditShift.form.checkOut.timeRange[1])}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Kantor
            </label>
            <Select
              className="appearance-none block w-full"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              defaultValue={defaultValue}
              onChange={(e) => {
                actionsEditShift.updateForm({
                  offices: e.map((o) => o.value),
                });
              }}
            />
          </div>
        </div>

        {errorUi}

        <button className="w-full bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
          Ubah
        </button>
      </form>
    </React.Fragment>
  );
}
