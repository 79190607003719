import { useForgotPasswordStore } from "../stores";
import { Link } from "react-router-dom";
import { Alert, Loader } from "../../App/components";

export function ForgotPasswordForm() {
  const [state, actions] = useForgotPasswordStore();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await actions.login();
  };

  let errorUi;
  if (state.error) {
    errorUi = <Alert type="error" body={state.error} />;
  }

  let loadingUi;
  if (state.loading) {
    loadingUi = <Loader />;
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6"
      action="#"
      method="POST"
    >
      {loadingUi}
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Nomor Telepon
        </label>
        <div className="mt-1">
          <input
            value={state.phone}
            onChange={(event) => {
              actions.setState({ phone: event.target.value });
            }}
            id="phone"
            name="phone"
            type="tel"
            autoComplete="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div>

      {errorUi}
      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Kirim SMS OTP
        </button>
      </div>
      <div>
        <Link
          to="/"
          className="w-full flex justify-center py-2 px-4 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
        >
          Kembali
        </Link>
      </div>
    </form>
  );
}
