import React, { useEffect } from "react";
import { TopNav, MobileNav } from "../App/components";

export default function Org() {
  useEffect(() => {
    document.title = `Org`;
  }, []);

  return (
    <>
      <MobileNav />
      <TopNav />
      {/* Start Header */}
      <div className="my-6 lg:my-8 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between">
        <div>
          <p className="flex items-center text-blue-700 text-xs">
            <span>Portal</span>
            <span className="mx-2">&gt;</span>
            <span>Dashboard</span>
            <span className="mx-2">&gt;</span>
            <span>KPIs</span>
          </p>
          <h4 className="text-2xl font-bold leading-tight text-gray-800">
            Org Dan Jabatan
          </h4>
        </div>
        <div className="mt-6 lg:mt-0">
          <button className="mr-3 bg-gray-200 hover:bg-gray-300 transition duration-150 ease-in-out focus:outline-none rounded text-blue-700 px-5 py-2 text-sm">
            Back
          </button>
          <button className="transition duration-150 ease-in-out hover:bg-blue-600 focus:outline-none border bg-blue-700 rounded text-white px-8 py-2 text-sm">
            Edit Profile
          </button>
        </div>
      </div>
      {/* End Header */}
      <div className="container mx-auto px-6 min-h-screen">
        {/* Remove class [ h-64 ] when adding a card block */}
        {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
        <div className="w-full h-64 rounded border-dashed border-2 border-gray-300">
          ORG
        </div>
      </div>
    </>
  );
}
