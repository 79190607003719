import { createStore, createHook } from "react-sweet-state";
import { detailApi, editApi } from "../api";
import { toast } from "react-toastify";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    form: {
      code: "",
      description: "",
      checkIn: {
        location: "",
        timeRange: [1000, 1000],
      },
      checkOut: {
        location: "",
        timeRange: [1000, 1000],
      },
      offices: [],
    },
  },
  // actions that trigger store mutation
  actions: {
    updateForm:
      (updateForm) =>
      async ({ setState, getState }) => {
        const replaceForm = { ...getState().form, ...updateForm };

        setState({ form: replaceForm });
      },

    findData:
      (_id) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, form: {} });

        try {
          const resp = await detailApi(_id);

          if (resp.data && resp.data.code === 200) {
            if (!resp.data.data) {
              const errMsg = "Data not found";
              setState({
                error: errMsg,
              });
              toast.error(errMsg);
              return;
            }
            setState({ form: { offices: [], ...resp.data.data } });
          }
        } catch (err) {
          const errMsg =
            err && err.response ? err.response.data : "Unknown error";
          setState({
            error: errMsg,
          });
          toast.error(errMsg);
        }

        setState({ isLoading: false });
      },
    updateData:
      (onSuccess) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          const resp = await editApi(getState().form._id, getState().form);

          if (resp.data && resp.data.code === 200) {
            setState({
              isLoading: false,
            });
            if (onSuccess) {
              onSuccess();
            }
            return;
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
          });
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "editSettingShiftService",
});

export const useEditStore = createHook(Store);
