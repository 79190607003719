import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import { useListStore, useRemoveStore } from "./stores";
import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { ListItem, ListHeader } from "./components";

export default function Home() {
  const [stateList, actionsList] = useListStore();
  const [stateRemove] = useRemoveStore();

  useEffect(() => {
    document.title = `Kantor - Pengaturan`;
    actionsList.loadData();
  }, []);

  let contentUi = <Loader />;
  if (!stateList.isLoading && !stateRemove.isLoading) {
    contentUi = (
      <div className="pb-8">
        <ListHeader />
        <ListView
          data={stateList.data}
          item={ListItem}
          className="w-full bg-transparent border-0"
        />
        <Pager
          pageSizes={[5, 10, 20, 25]}
          skip={stateList.skip}
          take={stateList.limit}
          onPageChange={(e) => {
            actionsList.updateState({ skip: e.skip, limit: e.take });
            actionsList.loadData();
          }}
          total={stateList.total}
          className="w-full "
        />
      </div>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Daftar Kantor"
        childs={[
          { text: "Pengaturan", link: "" },
          { text: "Kantor", link: "/setting/office" },
          { text: "Daftar Kantor", link: "" }
        ]}
        button={{text: 'Tambah', link: '/setting/office/add', icon: 'add'}}
      />
      <div className="container mx-auto px-6 min-h-screen mb-6">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
