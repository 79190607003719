import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader } from "../../App/components";
import { FormBoxEdit } from "./components";
import { useEditStore } from "./stores";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";

export default function Edit() {
  const { id } = useParams();
  const [state, actions] = useEditStore();

  useEffect(() => {
    document.title = `Ubah Kantor - Pengaturan`;
    actions.findData(id);
  }, []);

  let contentUi = <Loader />;

  if (!state.isLoading) {
    contentUi = (
      <React.Fragment>
        <FormBoxEdit />
      </React.Fragment>
    );
  }

  if (state.error && !state._id) {
    contentUi = <Redirect to="/setting/office" />;
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <div className="container mx-auto px-6 min-h-screen">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
