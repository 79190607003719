import { createStore, createHook } from "react-sweet-state";
import { listApi } from "../api";
import Joi from "joi";

const queryRules = Joi.object({
  limit: Joi.number().integer().min(1),
  skip: Joi.number().integer().min(0),
  search: Joi.string().allow("", null),
  unitOrgs: Joi.array().items(Joi.string()),
});

const initState = {
  isLoading: false,
  error: null,
  limit: 10,
  skip: 0,
  search: "",
  unitOrgs: [],
  data: [],
  total: 0,
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    updateState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },

    loadData:
      () =>
      async ({ setState, getState, actions }) => {
        setState({ isLoading: true, error: null, data: [] });

        let query = { ...getState() };

        //validate and sanitize
        try {
          query = await queryRules.validateAsync(query, {
            stripUnknown: true,
          });
        } catch (err) {
          setState({
            error: err.details[0].message,
            isLoading: false,
          });
          return false;
        }

        try {
          const resp = await listApi(query);
          if (resp.data && resp.data.code === 200) {
            setState({
              data: resp.data.data,
              total: resp.data.total,
              isLoading: false,
            });
            return true;
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "listOfficeService",
});

export const useListStore = createHook(Store);
