import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page404 from "../Page404";
import Transaction from "./Transaction";
import Employee from "./Employee";

export default function Setting() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/transaction`}>
          <Transaction />
        </Route>
        <Route path={`${path}/employee`}>
          <Employee />
        </Route>
        <Route path={`${path}/*`}>
          <Page404 />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
