import React from "react";
import { Link } from "react-router-dom";
import { useLayout, useMenu, useAuthStore } from "../stores";

export const MobileNav = () => {
  const [stateLayout, actionsLayout] = useLayout();
  const [stateMenu, actionsMenu] = useMenu();
  const [stateAuth, actionsAuth] = useAuthStore();

  const menuItem = stateMenu.data.map((menu, idx) => {
    if (!menu.childs) {
      return (
        <Link key={idx} to={menu.route}>
          <li className="pt-8">
            <div className="cursor-pointer text-gray-700 hover:text-blue-500 flex items-center">
              <div className="md:w-6 md:h-6 w-5 h-5">
                <span className="text-xl material-icons-outlined">
                  {menu.icon}
                </span>
              </div>
              <p className="ml-3 text-lg">{menu.text}</p>
            </div>
          </li>
        </Link>
      );
    }

    let childUi;

    if (stateMenu.extend === menu.route) {
      childUi = (
        <div key={idx}>
          <ul className="my-3">
            {menu.childs.map((child, childIdx) => {
              return (
                <Link to={child.route} key={childIdx}>
                  <li className="cursor-pointer text-gray-700 hover:text-blue-500 text-sm py-2 px-6">
                    {child.text}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      );
    }

    return (
      <li
        key={idx}
        onClick={() =>
          actionsMenu.setExtend(
            stateMenu.extend === menu.route ? "" : menu.route
          )
        }
        className="cursor-pointer text-gray-700 hover:text-blue-500 text-gray-700 pt-8"
      >
        <div className="flex items-center">
          <div className="cursor-pointer flex items-center">
            <div className="md:w-6 md:h-6 w-5 h-5">
              <span className="text-xl material-icons-outlined">
                {menu.icon}
              </span>
            </div>
            <p className="ml-3 text-lg">{menu.text}</p>
          </div>
          <span className="ml-2 material-icons-outlined text-sm">
            keyboard_arrow_down
          </span>
        </div>
        {childUi}
      </li>
    );
  });
  return (
    <div
      className={
        stateLayout.showMobileMenu
          ? "w-full h-full absolute z-40  transform  translate-x-0 "
          : " w-full h-full absolute z-40  transform -translate-x-full"
      }
    >
      <div
        className="bg-gray-800 opacity-50 inset-0 fixed w-full h-full"
        onClick={() =>
          actionsLayout.setShowMobileMenu(!stateLayout.showMobileMenu)
        }
      />
      <div className="w-64 z-20 absolute left-0 z-40 top-0 bg-white shadow flex-col justify-between transition duration-150 ease-in-out pb-2">
        <div className="flex flex-col justify-between h-full">
          <div className="px-6 pt-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src="/logo.png" alt="logo" className="w-8" />
                <p className="text-bold md:text2xl text-base pl-3 text-gray-800">
                  {stateMenu.mainTitle}
                </p>
              </div>
              <div
                className=" text-gray-800"
                onClick={() =>
                  actionsLayout.setShowMobileMenu(!stateLayout.showMobileMenu)
                }
              >
                <span className="text-2xl material-icons-outlined text-gray-500">
                  close
                </span>
              </div>
            </div>
            <ul className="f-m-m">{menuItem}</ul>
          </div>
          <div className="w-full mt-8">
            <div className="flex mb-4 w-full px-6"></div>
            <div className="border-t border-gray-300">
              <div className="w-full flex items-center justify-between px-6 pt-1">
                <div className="flex items-center">
                  <div className="h-8 w-8 mb-4 lg:mb-0 mr-4 bg-blue-600  shadow-md flex justify-center items-center rounded-full">
                    <p className="text-white font-bold text-xs">
                      {stateAuth.data.name.match(/\b(\w)/g).join("")}
                    </p>
                  </div>
                  <p className=" text-gray-800 text-base leading-4 ml-2">
                    {stateAuth.data.name}
                  </p>
                </div>
                <ul className="flex">
                  <li className="cursor-pointer pt-5 pb-3 text-gray-500">
                    <span className="material-icons-outlined">
                      account_circle
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      actionsAuth.logout();
                    }}
                    className="cursor-pointer text-gray-500 pt-5 pb-3 pl-3"
                  >
                    <span className="material-icons-outlined">logout</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
