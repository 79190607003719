import { createStore, createHook } from "react-sweet-state";
import { detailApi } from "../api";
import { toast } from "react-toastify";

const initState = {
  isLoading: false,
  error: null,
  _id: "",
  name: "",
  description: "",
  location: { type: "Point", coordinates: [0.0, 0.0] },
  unitOrgs: [],
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    findData:
      (_id) =>
      async ({ setState, getState, actions }) => {
        actions.resetState();
        setState({ isLoading: true });

        try {
          const resp = await detailApi(_id);
          if (resp.data && resp.data.code === 200) {
            if (!resp.data.data) {
              const errMsg = "Data not found";
              setState({
                error: errMsg,
              });
              toast.error(errMsg);
              return false;
            }
            setState({ ...resp.data.data, isLoading: false });
            return true;
          }
        } catch (err) {
          const errMsg =
            err && err.response ? err.response.data : "Unknown error";
          setState({
            isLoading: false,
            error: errMsg,
          });
          toast.error(errMsg);
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "detailDetailOfficeService",
});

export const useDetailStore = createHook(Store);
