import React from "react";
import { Link } from "react-router-dom";
import { useAuthStore, useLayout } from "../stores";

export const TopNavAvatar = () => {
  const [stateLayout, actionsLayout] = useLayout();
  const [stateAuth, actionsAuth] = useAuthStore();

  let popoverUi;

  if (stateLayout.showMenuTopAvatar) {
    popoverUi = (
      <ul className="p-2 w-40 border-r bg-white absolute rounded z-40 left-0 shadow mt-40">
        <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-blue-700 focus:text-blue-700 focus:outline-none">
          <Link to={"/profile"}>
            <div className="flex items-center">
              <span className="material-icons-outlined">account_circle</span>
              <span className="ml-2">Profil Saya</span>
            </div>
          </Link>
        </li>
        <li
          onClick={actionsAuth.logout}
          className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-blue-700 flex items-center focus:text-blue-700 focus:outline-none"
        >
          <span className="material-icons-outlined">logout</span>
          <span className="ml-2">Keluar</span>
        </li>
      </ul>
    );
  }
  return (
    <div
      aria-haspopup="true"
      className="cursor-pointer w-full flex items-center justify-end relative"
      onClick={() =>
        actionsLayout.setShowMenuTopAvatar(!stateLayout.showMenuTopAvatar)
      }
    >
      {popoverUi}
      <div className="h-10 w-10 mb-4 lg:mb-0 mr-4 bg-blue-600 shadow-md flex justify-center items-center rounded-full">
        <p className="text-white font-bold text-sm">
          {stateAuth.data.name.match(/\b(\w)/g).join("")}
        </p>
        {/* <img src={`https://cloud.eabsen.com/files/photos/profile/slt/${stateAuth.data._id}/thumb_c_mini.jpg`} /> */}
      </div>
      <p className="text-gray-800 text-sm ml-2">{stateAuth.data.name}</p>
    </div>
  );
};
