import React, { useEffect, Fragment, useRef, useState } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import {
  useListOfficePersonStore,
  useListPersonStore,
  useCreateStore,
  useDetailOfficeStore,
  useRemoveStore,
} from "./stores";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { Dialog, Transition } from "@headlessui/react";
import { ListItemPerson } from "./components";

export default function Home() {
  const { id } = useParams();
  const [stateList, actionsList] = useListOfficePersonStore();
  const [, actionsCreate] = useCreateStore();
  const [stateRemove] = useRemoveStore();
  const [statePerson, actionsStatePerson] = useListPersonStore();

  const [stateDetailOffice, actionDetailOffice] = useDetailOfficeStore();

  const [openSelect, setOpenSelect] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedItem] = useState("");
  const cancelButtonRef = useRef(null);

  const listPerson = [];
  const listExcludePerson = [];

  const updateFilteredPerson = () => {
    statePerson.data.forEach((item) =>
      stateList.data.forEach((existItem) => {
        if (existItem.personId === item._id) {
          listExcludePerson.push(item._id);
        }
      })
    );
    statePerson.data.forEach((item) => {
      listPerson.push(item);
    });
    return listPerson.filter((item) => !listExcludePerson.includes(item._id));
  };

  let handleSelect = async () => {
    setOpenSelect(true);
  };

  let handleClose = async () => {
    actionsStatePerson.updateStore({ search: "" });
    setOpenSelect(false);
    setShowResult(false);
  };

  let handleSearch = async (e) => {
    e.preventDefault();
    await actionsStatePerson.loadData();
    setShowResult(true);
  };

  let handleLoadMore = async () => {
    actionsStatePerson.updateStore({ limit: statePerson.limit + 10 });
    actionsStatePerson.loadData();
  };

  let handleAdd = async (_id) => {
    actionsCreate.updateState({ officeId: id, personId: _id });
    if (await actionsCreate.createData()) {
      toast.success("Berhasil tambah data");
      actionsList.updateStore({ officeId: id });
      actionsList.loadData();
      actionsStatePerson.loadData();
    }
  };

  useEffect(() => {
    document.title = `Daftar Pegawai - Pegawai`;
    actionsList.updateStore({ officeId: id });
    actionsList.loadData();
    actionsStatePerson.loadData();
    actionDetailOffice.findData(id);
  }, []);

  let contentUi = <Loader />;
  if (!stateList.isLoading && !stateRemove.isLoading) {
    contentUi = (
      <div className="pb-8">
        <ListView
          data={stateList.data}
          item={ListItemPerson}
          className="w-full bg-transparent border-0"
        />
        <Pager
          pageSizes={[5, 10, 20, 25]}
          skip={stateList.skip}
          take={stateList.limit}
          onPageChange={(e) => {
            actionsList.updateStore({ skip: e.skip, limit: e.take });
            actionsList.loadData();
          }}
          total={stateList.total}
          className="w-full "
        />
      </div>
    );
  }

  const selectItem = updateFilteredPerson().map((item, idx) => (
    <div key={idx} className="max-w-3xl w-full mx-auto z-10" title={item.name}>
      <div className="flex flex-col">
        <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4">
          <div className="flex-none sm:flex">
            <div className="flex-1 sm:ml-5 justify-evenly">
              <div className="flex items-center justify-between sm:mt-2">
                <div className="flex items-center">
                  <div className="flex flex-col">
                    <div className="w-full flex-none text-md text-gray-800 font-bold leading-none">
                      {item.name}
                    </div>
                    <div className="flex text-xs">
                      <div className="flex items-center text-sm mr-1">
                        <span className="material-icons-outlined text-sm">
                          badge
                        </span>
                      </div>

                      <div className="flex items-center text-sm mr-1">
                        <span className="text-black-300 text-xs">
                          {item._id}
                        </span>
                      </div>

                      <div className="flex items-center text-sm mr-1">
                        <span className="text-black-300">|</span>
                      </div>

                      <div className="flex items-center text-sm mr-1">
                        <span className="text-black-300 text-xs">
                          {item.no}
                        </span>
                      </div>
                    </div>
                    <div className="flex-auto text-black-300 my-1 text-xs">
                      <span title={item.jobName}>{item.jobName}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex sm:ml-5 justify-evenly">
              <button
                onClick={() => handleAdd(item._id)}
                type="button"
                title={`Tambah ${item.name} ke ${stateDetailOffice.data.name}`}
                className="flex items-center text-xs text-blue-700"
              >
                <span className="material-icons-outlined">add</span>
                <span className="ml-1 font-bold">Tambah</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  let contentResult = "";
  if (showResult) {
    contentResult = (
      <div className="w-full">
        {selectItem}
        <div className="max-w-3xl w-full mx-auto z-10 text-center">
          <button
            type="button"
            className="text-sm text-blue-700"
            onClick={() => handleLoadMore()}
          >
            <span className="ml-1 font-bold" title="Muat data lain">
              Muat Data
            </span>
          </button>
        </div>
      </div>
    );
  }

  let selectUi = (
    <Transition.Root show={openSelect} as={Fragment} item={selectedItem}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openSelect}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="sm:items-center px-2 py-4">
                <div className="flex-grow">
                  <h3 className="font-bold px-2 py-3 leading-tight">
                    Tambah Pegawai
                  </h3>
                  <form onSubmit={(e) => handleSearch(e)}>
                    <div className="shadow flex">
                      <input
                        type="text"
                        placeholder="Cari pegawai"
                        className="w-full rounded p-2 border-transparent"
                        value={statePerson.search}
                        onChange={(event) =>
                          actionsStatePerson.updateStore({
                            search: event.target.value,
                          })
                        }
                      />
                      <button className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light">
                        <i className="material-icons">search</i>
                      </button>
                    </div>
                  </form>
                  {contentResult}
                </div>
              </div>
              <div className="bg-grayremoveApi-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                  ref={cancelButtonRef}
                >
                  Batal
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title={`Daftar Pegawai`}
        childs={[
          { text: "Pegawai" },
          { text: "Kantor", link: "/person/office" },
          { text: stateDetailOffice.data.name, link: "" },
        ]}
        button={{ text: "Tambah", click: () => handleSelect(), icon: "add" }}
      />
      <div className="container mx-auto px-6 min-h-screen mb-6">
        {selectUi}
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
