import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import { useListOrgStore } from "./stores";
import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { ListItemOrg } from "./components";

export default function Home() {
  const [stateList, actionsList] = useListOrgStore();

  useEffect(() => {
    document.title = `Berdasarkan Pegawai - Laporan`;
    actionsList.loadData();
  }, []);

  let handleSearch = async (e) => {
    e.preventDefault();
    await actionsList.loadData();
    actionsList.updateState({ search: "" });
  };

  let contentUi = <Loader />;
  if (!stateList.isLoading) {
    contentUi = (
      <>
        <div className="pb-8">
          <form onSubmit={(e) => handleSearch(e)}>
            <div className="flex">
              <div className="w-full">
                <div className="shadow flex">
                  <input
                    type="text"
                    placeholder="Cari"
                    className="w-full rounded p-2 border-transparent"
                    value={stateList.search}
                    onChange={(event) =>
                      actionsList.updateState({ search: event.target.value })
                    }
                  />
                  <button className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light">
                    <i className="material-icons">search</i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ListView
          data={stateList.data}
          item={ListItemOrg}
          className="w-full bg-transparent border-0"
        />
        <Pager
          pageSizes={[5, 10, 20, 25]}
          skip={stateList.skip}
          take={stateList.limit}
          onPageChange={(e) => {
            actionsList.updateState({ skip: e.skip, limit: e.take });
            actionsList.loadData();
          }}
          total={stateList.total}
          className="w-full "
        />
      </>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Daftar Organisasi"
        childs={[
          { text: "Laporan", link: "" },
          { text: "Berdasarkan Pegawai", link: "/report/transaction" },
          { text: "Pilih Organisasi", link: "" },
        ]}
      />
      <div className="container mx-auto px-6 min-h-screen mb-6">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
