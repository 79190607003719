import { createStore, createHook } from "react-sweet-state";
import { fbAuth } from "../../services";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,

    error: null,
  },
  actions: {
    login:
      ({ phone, password }) =>
      async ({ setState, getState }) => {
        if (!phone || phone.trim().length < 9) {
          return setState({ error: "No Handphone minimal 9 angka" });
        }
        if (!password || password.trim().length < 6) {
          return setState({ error: "Password minimal 6 karakter" });
        }

        let emailPhone = `${phone}`;
        if (emailPhone.startsWith("08")) {
          emailPhone = emailPhone.replace("08", "+628");
        }

        emailPhone = `m${emailPhone.replace("+", "")}@eabsen.com`;

        setState({ isLoading: true, error: null });
        try {
          await fbAuth.signInWithEmailAndPassword(emailPhone, password);
        } catch (err) {
          let errMsg = err.message;
          console.log(err.code);
          if (err.code === "auth/user-not-found") {
            errMsg = "Nomor hp belum terdaftar";
          }

          if (err.code === "auth/wrong-password") {
            errMsg = "Password salah";
          }

          return setState({ error: errMsg, isLoading: false });
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "login",
});

export const useLoginStore = createHook(Store);
