import React from "react";
import { Link } from "react-router-dom";

export function ListItemPerson(props) {
  const item = props.dataItem;

  return (
    <>
    <div className="bg-white p-4 border-b-2 border-2 mb-2">
        <div className="flex-1 flex flex-col pb-4">
          <Link
            to={`/report/employee/show/${item._id}`}
            className="text-md font-medium text-blue-500"
          >
            {item.name}
          </Link>
          <div className="flex text-xs">
          <div className="flex items-center text-sm mr-1">
           <span className="material-icons-outlined text-sm text-green-600">badge</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-black-300 text-xs">{item._id}</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-gray-600">|</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-black-300 text-xs">{item.no}</span>
          </div>
          
        </div>
        <div className="flex text-xs">
          <div className="flex items-center text-sm mr-1">
           <span className="material-icons-outlined text-sm text-green-600">{item.sex === "m" ? 'male' : 'female'}</span>
          </div>
          <div className="flex items-center text-xs mr-1">
          <span className="text-green-600">{item.sex === "m" ? 'Laki-laki' : 'Perempuan'}</span>
          </div>
          <div className="flex items-center text-sm mr-1">
            <span className="text-gray-600">|</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-black-300 text-xs text-black-600">{item.mobile}</span>
          </div>
        </div>
        <div className="flex mt-1">
          <div className="flex items-center mr-1">
            <span className="text-black-100 text-xs">{item.jobName}</span>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
