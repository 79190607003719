import { apiClient } from "../../../services";

export const listApi = function ({ limit, skip, search, unitOrgs }) {
  return apiClient.get(
    `/admin/office/list?limit=${limit}&skip=${skip}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};

export const listPersonApi = function ({ limit, search, unitOrgs }) {
  return apiClient.get(
    `/admin/person/list?limit=${limit}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};

export const listOfficePersonApi = function ({ limit, skip, search, officeId }) {
  return apiClient.get(
    `/admin/office-person/office/${officeId}/list?limit=${limit}&skip=${skip}&search=${search}`
  );
};

export const detailOfficeApi = function (_id) {
  return apiClient.get(`/admin/office/detail/${_id}`);
};

export const addOfficePersonApi = function ({ officeId, personId }) {
  return apiClient.post(`/admin/office-person/office/${officeId}/person/add`, {
    personId: personId,
  });
};

export const removeOfficePersonApi = function ({ officeId, personId }) {
  console.log(personId);
  return apiClient.post(`/admin/office-person/office/${officeId}/person/remove`, {
    personId: personId,
  });
};

export const allOrgApi = function () {
  return apiClient.get(`/admin/org/all/main.js`);
};