import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/id";
import { convertLocation, getFirstDay, getLastDay, getSunday, addDay} from "../../../App/action";
import { useTypeExceptionStore } from "./../../../App/stores";
import { getScheduleApi} from "../api";
import { Link } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function ListItem(props) {
  let currentDate = props.currentDate;
  const [visible, setVisible] = React.useState(false);
  const toggleDialog = () => {
    setVisible(!visible);
  };

  const [stateSchedule, setSchedule] = useState({ data: null });
  const [TypeException] = useTypeExceptionStore();

  const getSchedule = async (personId, date) => {
    const resp = await getScheduleApi({ personId, date });

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }
      setSchedule({ data: resp.data });
    }
  };

  useEffect(() => {
  getSchedule(props.personId, currentDate)

  }, []);

  const showPhoto = <div>
  {visible && (
    <Dialog title={"Foto"} onClose={toggleDialog}>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        <img src="https://cloud.eabsen.com/files/photos/profile/slt/3186/thumb.jpg" />
      </p>
    </Dialog>
  )}
</div>;

  let data = [];
  if(stateSchedule.data)
  {
    data = stateSchedule.data.data;
  }
  console.log(data);

  return (
    data ?
    <>
      {
        data.exception ?
        <td className="border text-center text-sm text-yellow-300 px-2 py-6">
          {data.shift != null && <span title={data.exception}>{TypeException[data.exception]}</span>}
        </td>
        :
        <td className="border text-center text-sm font-semibold text-green-500 px-2 py-6">
          {data.shift != null && <span title={data.shift.description}>{data.shift.code}</span>}
        </td>
      }

      { data ?
        <td className="border text-sm px-2 py-6">
            {showPhoto}
            { data.checkIn &&
              data.checkIn.map((i,x) => 
                <span key={x} className="">
                    <span
                      className={i.invalidCode ? `cursor-pointer inline-block rounded-full text-white bg-red-400 hover:bg-red-400 duration-300 text-xs font-bold mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100` : `cursor-pointer inline-block rounded-full text-white bg-green-400 hover:bg-green-400 duration-300 text-xs font-bold mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100`}
                      title="Lihat Detail"
                      onClick={toggleDialog}
                    >
                    <span
                    className="material-icons-outlined text-sm"
                    title={`Absen dari ${convertLocation(data.shift.checkIn.location ? data.shift.checkIn.location : 'Tidak diketahui')}`}>{ 
                        data.shift.checkIn.location == 'Office' ? 'corporate_fare' : 'home'}
                    </span>{
                    i.device ? <span className="material-icons-outlined text-sm" title={`Absen melalui ${i.device.replace("ios", "iOS").replace("android","Android").replace("report", "Laporan")}`}>{i.device.replace("android", "android").replace("ios","phone_iphone").replace("web", "desktop_windows").replace("report", "summarize")}</span> : <span className="material-icons-outlined text-sm" title="Web">desktop_windows</span>
                    }{" "}
                    {
                        i.dateTime !== null ? moment(i.dateTime,"YYYYMMDDhhmmss").format("YYYY-MM-DD HH:mm:ss") : ''
                    }{" "}
                    <a
                      href={i.coordinates ? `https://www.google.com/maps/place/${i.coordinates.coordinates[0]},${i.coordinates.coordinates[1]}` : ''} target="_blank">
                        <span
                        className="material-icons-outlined text-sm"
                        title={`Absen dari ${i.coordinates ? i.coordinates.coordinates : 'Tidak diketahui'}`}>place</span>
                    </a>
                    {
                      i.invalidReason &&
                        <span className="material-icons-outlined text-sm text-white hover:text-white"
                        title={i.invalidReason}>error</span>
                    }
                    </span>
                    
                </span>
              )
            }
        </td>   
        :
        <td className="border text-sm px-2 py-6"></td>
      }
      <td className="border text-center text-sm px-2 py-6">
        {data.status && data.shift.status}
      </td>
  </>
  :
  <>
    <td className="border text-center text-xs px-2 py-6">LIBUR</td>
    <td className="border px-2 py-6"></td>
    <td className="border px-2 py-6"></td>
  </>
  );
}
