import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/id";
import { useAddSchedule, useDeleteScheduleStore } from "./../stores";
import { getScheduleApi, updateSchedulesApi, removeSchedulesApi } from "../api";
import { today } from "../../../App/action";

export function ListAbsen(props) {
  const item = props.data;
  const shift = props.shift;
  const date = props.date;
  const persons = props.persons;
  const [selectedShift, setSelectedShift] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [stateSchedule, setSchedule] = useState({ data: null });
  const [, actionsStateAddSchedule] = useAddSchedule();
  const [, actionsStateDeleteSchedule] = useDeleteScheduleStore();
  const [dayDiff, setDayDiff] = useState(0);

  let listPersons = [];
  persons.forEach((i, x) => {
    listPersons.push(i._id);
  });

  const getSchedule = async (personId, date) => {
    const resp = await getScheduleApi({ personId, date });

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }
      setSchedule({ data: resp.data });
      setShowLoader(false);
      setVisible(false);
    }
  };

  const copySchedule = async (param) => {
    const resp = await updateSchedulesApi(param);

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }

      /* param.personIds.map((i,x) => {
        props.update(i);
      }); */
      props.update();
    }
  };

  const removeAllSchedule = async (param) => {
    const resp = await removeSchedulesApi(param);

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }

      /* param.personIds.map((i,x) => {
        props.update(i);
      }); */
      props.update();
    }
  };

  useEffect(() => {
    console.log(moment(today).diff(date,'days'));
    getSchedule(item._id, date);
  }, []);

  const toggleDialog = () => {
    setVisible(!visible);
    setSelectedShift("");
  };

  const handleSave = () => {
    setShowLoader(true);
    if (selectedShift !== "") {
      actionsStateAddSchedule.updateForm({
        personId: item._id,
        date: date,
        shiftId: selectedShift,
      });
      actionsStateAddSchedule.createData(() => {
        toast.success("Berhasil menyimpan data");
        getSchedule(item._id, date);
      });
    }
  };

  const copyAll = (shiftId, date, personIds) => {
    copySchedule({ shiftId, date, personIds });
  };

  const deleteItem = () => {
    setShowLoader(true);
    actionsStateDeleteSchedule.deleteData(
      {
        personId: item._id,
        date: date,
      },
      () => {
        toast.warning("Berhasil menghapus data");
        getSchedule(item._id, date);
      }
    );
  };

  const deleteAll = (date, personIds) => {
    removeAllSchedule({ date, personIds });
  };

  const loader = (
    <div className="flex-none flex items-center justify-center w-full h-ful py-1">
      <svg
        fill="none"
        className="w-6 h-6 animate-spin"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );

  const inputAbsen = (
    <>
      {!showLoader ? (
        <div className="mb-1 input-add-jadwal flex">
          <div className="flex-auto">
            <select
              className="form-select block w-full border border-gray-300 mt-1 text-xs"
              onChange={(o) => setSelectedShift(o.target.value)}
            >
              <option></option>
              {shift.map((i, x) => (
                <option key={x} value={i._id}>
                  {i.code}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-auto mt-1 mr-1 ml-1">
            <button
              className="flex-none flex items-center justify-center w-full h-full text-gray-400 border border-gray-300"
              onClick={handleSave}
            >
              <span className="material-icons-outlined">save</span>
            </button>
          </div>
        </div>
      ) : (
        loader
      )}
    </>
  );
  
  return (
    <td>
      {stateSchedule.data &&
      stateSchedule.data.data &&
      stateSchedule.data.data.shift ? (
        <>
          {visible ? (
            inputAbsen
          ) : !showLoader ? (
            <div
              className="min-w-full cursor-pointer text-center px-5 py-1 align-middle text-xs"
              title={`${stateSchedule.data.data.personName} pada ${moment(
                stateSchedule.data.data.date,
                "YYYYMMDD"
              ).format("dddd, Do MMMM YYYY")}`}
            >
              <div className="py-1">
                {
                  (moment(date).diff(today,'days') >= 0) ?
                    <>
                      <span
                      className="cursor-pointer material-icons-outlined align-middle text-xs text-gray-300 hover:text-green-600"
                      title="Salin"
                      onClick={() =>
                        copyAll(
                          stateSchedule.data.data.shift._id,
                          stateSchedule.data.data.date,
                          listPersons
                        )
                      }
                    >
                      copy
                    </span>
                    <span
                      className="cursor-pointer inline-block align-middle mx-2 font-medium"
                      onClick={toggleDialog}
                    >
                      {stateSchedule.data.data !== null &&
                      stateSchedule.data.data.shift !== null ? (
                        <span title={stateSchedule.data.data.shift.description}>
                          {stateSchedule.data.data.shift.code}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    <span
                      className="cursor-pointer material-icons-outlined align-middle text-xs text-gray-300 hover:text-red-600"
                      title="Hapus ini"
                      onClick={deleteItem}
                    >
                      delete
                    </span>
                    <span
                      className="cursor-pointer material-icons-outlined align-middle text-xs text-gray-300 hover:text-red-600"
                      title="Hapus semua di tanggal ini"
                      onClick={() =>
                        deleteAll(stateSchedule.data.data.date, listPersons)
                      }
                    >
                      clear
                    </span>
                  </>
                  :
                  <span
                    className="cursor-pointer inline-block align-middle mx-2 font-medium"
                    onClick={toggleDialog}
                  >
                  {stateSchedule.data.data !== null &&
                  stateSchedule.data.data.shift !== null ? (
                    <span title={stateSchedule.data.data.shift.description}>
                      {stateSchedule.data.data.shift.code}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                }
                
              </div>
            </div>
          ) : (
            loader
          )}
        </>
      ) : (
        <>
          {visible ? (
            inputAbsen
          ) : !showLoader ? (
            (moment(date).diff(today,'days') >= 0) ?
              <button
                className="min-w-full cursor-pointer bg-blue-600 hover:bg-blue-500 shadow-xl px-5 py-1 inline-block text-blue-100 hover:text-white rounded"
                title={`${item.personName} pada ${moment(date, "YYYYMMDD").format(
                  "dddd, Do MMMM YYYY"
                )}`}
                onClick={toggleDialog}
              >
                <span className="material-icons-outlined align-middle text-sm">
                  add_box
                </span>
                <span className="align-middle text-xs">Jadwal</span>
              </button>
              :
              <button
                className="min-w-full cursor-pointer bg-blue-600 hover:bg-blue-500 shadow-xl px-5 py-1 inline-block text-blue-100 hover:text-white rounded"
                title={`${item.personName} pada ${moment(date, "YYYYMMDD").format(
                  "dddd, Do MMMM YYYY"
                )}`}
                disabled=""
              >
                <span className="material-icons-outlined align-middle text-sm">
                  add_box
                </span>
                <span className="align-middle text-xs">Jadwal</span>
              </button>
          ) : (
            loader
          )}
        </>
      )}
    </td>
  );
}
