import React from "react";
import { useLoginStore } from "../stores";
import { Link } from "react-router-dom";
import { Alert, Loader } from "../../App/components";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";

export function LoginForm() {
  const [state, actions] = useLoginStore();

  const handleSubmit = async (dataItem) => {
    await actions.login(dataItem);
  };

  let errorUi;
  if (state.error) {
    errorUi = (
      <div className="mb-3">
        <Alert type="error" body={state.error} />{" "}
      </div>
    );
  }

  let loadingUi;
  if (state.loading) {
    loadingUi = <Loader />;
  }
  return (
    <>
      {loadingUi}
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Field name={"phone"} component={Input} label={"Nomor Hp"} />
              </div>

              <div className="mb-3">
                <Field
                  name={"password"}
                  component={Input}
                  label={"Password"}
                  type="password"
                />
              </div>
            </fieldset>
            <div className="flex justify-end mb-3">
              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Lupa password?
                </Link>
              </div>
            </div>
            {errorUi}
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                LOGIN
              </button>
            </div>
          </FormElement>
        )}
      />
    </>
  );
}
