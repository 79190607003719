import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

const Auth = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="*" component={Login} />
      </Switch>
    </Router>
  );
};

export default Auth;
