import { apiClient } from "../../../services";

export const listApi = function ({ limit, skip, search }) {
  return apiClient.get(
    `/admin/shift/list?limit=30&skip=${skip}&search=${search}`
  );
};

export const addApi = function (newData) {
  const allowed = ['code', 'description', 'checkIn', 'checkOut'];
  Object.keys(newData)
    .filter(key => !allowed.includes(key))
    .forEach(key => delete newData[key]);
  return apiClient.post(`/admin/shift/add`, newData);
};

export const detailApi = function (_id) {
  return apiClient.get(`/admin/shift/detail/${_id}`);
};

//https://api.epegawai.com/shift/admin/list?limit=1&skip=0&search=SH1KK
///shift/admin/edit/f2e81e90-49e3-43b4-a004-efbe9c8b8412 

export const editApi = function (_id, updateFields) {
  const allowed = ['code', 'description', 'checkIn', 'checkOut', 'offices'];
  Object.keys(updateFields)
    .filter(key => !allowed.includes(key))
    .forEach(key => delete updateFields[key]);
  return apiClient.post(`/admin/shift/edit/${_id}`, {disabled: false,...updateFields});
};

export const removeApi = function (_id) {
  //return apiClient.post(`/shift/admin/admin/remove/${_id}`);
  let updateFields = {
    disabled: true,
  };
  return apiClient.post(`/admin/shift/edit/${_id}`, updateFields);
};

export const allOfficeApi = function () {
  return apiClient.get(`/admin/office/all/data.js`);
};
