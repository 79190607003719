import React from "react";
import { Link } from "react-router-dom";

export function ListItem(props) {
  const item = props.dataItem;

  return (
    <>
    <Link
      to={`/person/schedule/show/${item._id}`}
      title={item.orgName}
    >
    <div className="bg-white p-4 border-b-2 border-2 mb-2">
      <div className="flex items-center text-sm">
            <span className="material-icons-outlined">corporate_fare</span>
            <span className="ml-1 text-md font-medium text-blue-500">
              {item.orgName}
            </span>
          </div>
    </div>
    </Link>
    </>
  );
}
