import { createStore, createHook } from "react-sweet-state";
import { detailApi } from "../api";
import { toast } from "react-toastify";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    data: {
      code: "",
      description: "",
      checkIn: {
        location: "",
        timeRange: [1000, 1000],
      },
      checkOut: {
        location: "",
        timeRange: [1000, 1000],
      },
      offices: [],
    },
    error: null,
  },
  // actions that trigger store mutation
  actions: {
    findData:
      (_id) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, form: {} });

        try {
          const resp = await detailApi(_id);

          if (resp.data && resp.data.code === 200) {
            if (!resp.data.data) {
              const errMsg = "Data not found";
              setState({
                error: errMsg,
              });
              toast.error(errMsg);
              return;
            }
            setState({ data: resp.data.data });
          }
        } catch (err) {
          const errMsg =
            err && err.response ? err.response.data : "Unknown error";
          setState({
            error: errMsg,
          });
          toast.error(errMsg);
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "detailDetailShiftService",
});

export const useDetailStore = createHook(Store);
