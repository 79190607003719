import { createStore, createHook } from "react-sweet-state";
import { checkAuth, loginApi } from "../../Auth/api";
import { fbAuth, apiClient } from "../../services";

async function loginToApi({ stateAuth, actionsAuth, fbUser }) {
  try {
    const idToken = await fbUser.getIdToken();
    const resp = await loginApi({ idToken });
    const { code, currentUser, token } = resp.data;

    if (code === 200) {
      window.localStorage.setItem("token", token);
      apiClient.defaults.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    actionsAuth.setState({ isAuth: true, data: currentUser });
  } catch (err) {
    console.log(err);
    actionsAuth.setState({
      initAuth: true,
      isAuth: false,
      data: {},
      error: err.message,
    });
    return;
  }

  actionsAuth.setState({ initAuth: true });
  return;
}

export function initAuth({ stateAuth, actionsAuth }) {
  fbAuth.onAuthStateChanged(function (fbUser) {
    if (!fbUser) {
      actionsAuth.setState({ initAuth: true, isAuth: false, data: {} });
      return;
    }
    actionsAuth.setState({ initAuth: false, isAuth: false, data: {} });
    loginToApi({ stateAuth, actionsAuth, fbUser });
  });
}

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    initAuth: false,
    isLoading: false,
    isAuth: false,
    data: {},
    error: null,
  },
  actions: {
    setState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    logout:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });

        try {
          await fbAuth.signOut();

          return setState({
            isLoading: false,
            error: null,
            initAuth: true,
            isAuth: false,
            data: {},
          });
        } catch (err) {
          setState({ isLoading: false, error: err.message });
        }
      },
    checkAuth:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        try {
          const resp = await checkAuth();

          if (resp.data && resp.data.code === 200) {
            setState({ isAuth: true, data: resp.data.data });
          }
        } catch (err) {
          setState({ isAuth: false, data: {} });
        }
        setState({ initialAuth: true, isLoading: false, error: null });
      },
  },
  // optional, mostly used for easy debugging
  name: "auth",
});

export const useAuthStore = createHook(Store);
