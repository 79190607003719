import React from "react";

export function Alert({ type, body }) {
  if (type === "warning") {
    return (
      <div className={`bg-yellow-50 border-l-4 border-yellow-400 p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="material-icons text-yellow-500 text-xl">
              report_problem
            </span>
          </div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium text-yellow-800`}>Warning</h3>
            <div className={`mt-2 text-sm text-yellow-700`}>{body}</div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "success") {
    return (
      <div className={`bg-yellow-50 border-l-4 border-green-400 p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="material-icons text-green-500 text-xl">
              check_circle
            </span>
          </div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium text-green-800`}>Success</h3>
            <div className={`mt-2 text-sm text-green-700`}>{body}</div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "info") {
    return (
      <div className={`bg-yellow-50 border-l-4 border-blue-400 p-4`}>
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="material-icons text-blue-500 text-xl">info</span>
          </div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium text-blue-800`}>Info</h3>
            <div className={`mt-2 text-sm text-blue-700`}>{body}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-yellow-50 border-l-4 border-red-400 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <span className="material-icons text-red-500 text-xl">cancel</span>
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-red-800`}>Error</h3>
          <div className={`mt-2 text-sm text-red-700`}>{body}</div>
        </div>
      </div>
    </div>
  );
}
