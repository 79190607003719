import firebase from "firebase/app";

// import "firebase/performance";
import "firebase/analytics";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBfDaZSD-SObbJhXpXA31EGw_xGa_u7KZU",
  authDomain: "eabsen-cloud.firebaseapp.com",
  databaseURL: "https://eabsen-cloud.firebaseio.com",
  projectId: "eabsen-cloud",
  storageBucket: "eabsen-cloud.appspot.com",
  messagingSenderId: "364298421849",
  appId: "1:364298421849:web:a0f8268ca06d15cb",
};

const fbApp = firebase.initializeApp(firebaseConfig);
fbApp.auth().languageCode = "id";

// export const fbPerf = fbApp.performance();
// export const fbMessaging = messaging;
export const fbAuth = fbApp.auth();
export const fbDb = fbApp.database();
export const fbDbFirestore = fbApp.firestore();
export const fbStorage = fbApp.storage();
export const fbDbTimestamp = firebase.database.ServerValue.TIMESTAMP;
export const fbDbFirestoreTimestamp =
  firebase.firestore.FieldValue.serverTimestamp();
