import React from "react";
import { Link } from "react-router-dom";

export function ListItem(props) {
  const item = props.dataItem;

  return (
    <>
    <div className="bg-white p-4 border-b-2 border-2 mb-2">
      <div className="flex-1 flex flex-col pb-4">
        <Link
          to={`/person/office/show/${item._id}`}
          className="text-md font-medium text-blue-500"
        >
          {item.name}
        </Link>
        <div className="mt-2 text-xs">
          <div className="flex items-center text-sm">
            <span className="material-icons-outlined">place</span>
            <span className="ml-1">{item.address}</span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
