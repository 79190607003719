import React, { useEffect } from "react";
import { TopNav, MobileNav } from "../App/components";

export default function Home() {
  useEffect(() => {
    document.title = `Home`;
  }, []);

  return (
    <>
      <MobileNav />
      <TopNav />
      {/* Start Header */}
    </>
  );
}
