import React from "react";

export const TopNavNotification = () => {
  return (
    <div className="w-16 xl:w-32 h-full flex items-center justify-center xl:border-r">
      <div className="relative">
        <div className="cursor-pointer w-6 h-6 xl:w-auto xl:h-auto text-gray-600">
          <span className="text-3xl material-icons-outlined">
            notifications
          </span>
        </div>
        <div className="animate-ping w-2 h-2 rounded-full bg-red-400 border border-white absolute inset-0 mt-1 mr-1 m-auto" />
      </div>
    </div>
  );
};
