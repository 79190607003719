import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import { useListPersonStore } from "./stores";
import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { ListItemPerson } from "./components";

export default function Persons() {
  const { id } = useParams();
  const [stateList, actionsList] = useListPersonStore();

  useEffect(() => {
    document.title = `Berdasarkan Pegawai - Laporan`;

    actionsList.updateStore({ unitOrgs: [id] });
    actionsList.loadData();
  }, []);

  let handleSearch = async (e) => {
    e.preventDefault();
    await actionsList.loadData();
    actionsList.updateStore({ search: "" });
  };

  let contentUi = <Loader />;
  if (!stateList.isLoading && stateList.data.personId !== "") {
    contentUi = (
      <>
        <div className="pb-8">
          <form onSubmit={(e) => handleSearch(e)}>
            <div className="flex">
              <div className="w-full">
                <div className="shadow flex">
                  <input
                    type="text"
                    placeholder="Cari"
                    className="w-full rounded p-2 border-transparent"
                    value={stateList.search}
                    onChange={(event) =>
                      actionsList.updateStore({ search: event.target.value })
                    }
                  />
                  <button className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light">
                    <i className="material-icons">search</i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ListView
          data={stateList.data}
          item={ListItemPerson}
          className="w-full bg-transparent border-0"
        />
        <Pager
          pageSizes={[5, 10, 20, 25]}
          skip={stateList.skip}
          take={stateList.limit}
          onPageChange={(e) => {
            actionsList.updateStore({ skip: e.skip, limit: e.take });
            actionsList.loadData();
          }}
          total={stateList.total}
          className="w-full "
        />
      </>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Daftar Pegawai"
        childs={[
          { text: "Laporan", link: "" },
          { text: "Berdasarkan Pegawai", link: "/report/employee" },
          { text: "Pilih Pegawai", link: "" },
        ]}
      />
      <div className="container mx-auto px-6 min-h-screen mb-6">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
