import { createStore, createHook } from "react-sweet-state";
import { listApi } from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    limit: 10,
    skip: 0,
    search: "",
    data: [],
    dataObject: {},
    total: 0,
    error: null,
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },

    loadData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null, limit: 10, data: [] });

        try {
          const resp = await listApi(getState());

          if (resp.data && resp.data.code === 200) {
            /* resp.data.data = resp.data.data.filter((data) => {
              return data.disabled !== true;
            }); // hide deleted data with soft delete
            resp.data.total = resp.data.data.length; // override total data after filter */
            setState({ data: resp.data.data, total: resp.data.total });
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
          });
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "listShiftService",
});

export const useListStore = createHook(Store);
