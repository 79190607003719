import React from "react";
import { useRemoveStore, useListOfficePersonStore } from "../stores";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export function ListItemPerson(props) {
  const { id } = useParams();
  const item = props.dataItem;

  const [, actionsRemove] = useRemoveStore();
  const [, actionsList] = useListOfficePersonStore();

  const deleteAction = async function () {
    if (await actionsRemove.deleteData(id, item.personId)) {
      toast.warning(`Berhasil hapus data ${item.personName}`);
      actionsList.updateStore({officeId: id});
      actionsList.loadData();
    }
  };
  const deleteSubmit = async (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Konfirmasi hapus",
      message: `Apakah yakin hapus ${item.personName} dari ${item.officeName}`,
      buttons: [
        {
          label: "Hapus",
          className: "bg-red-700",
          onClick: () => {
            deleteAction();
          },
        },
        {
          label: "Batal",
          className: "bg-gray-100 text-gray-800",
        },
      ],
    });
  };  

  return (
    <>
    <div key={item._id} className="bg-white p-4 border-b-2 border-2 mb-2">
      <div className="flex-1 flex flex-col pb-4">
        <Link
          className="text-md font-medium text-blue-500"
        >
          {item.personName}
        </Link>
        <div className="flex text-xs">
          <div className="flex items-center text-sm mr-1">
           <span className="material-icons-outlined text-sm">badge</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-black-300 text-xs">{item.personId}</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-gray-600">|</span>
          </div>

          <div className="flex items-center text-sm mr-1">
            <span className="text-black-300 text-xs">{item.personNo}</span>
          </div>
          
        </div>
        <div className="flex mt-1 text-xs">
          <div className="flex items-center text-xs mr-1">
            <span className="text-black-100">{item.officeName}</span>
          </div>
        </div>
      </div>
      <div className="flex">   
          <button
            type="button"
            className="flex items-center text-sm text-red-700"
            onClick={
              (e) => deleteSubmit(e)
            }
          >
            <span className="material-icons-outlined">clear</span>
            <span className="ml-1 font-bold">Hapus</span>
          </button>
      </div>
    </div>
    </>
  );
}
