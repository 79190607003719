import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import { convertTime, convertLocation } from "../../App/action";
import { useDetailStore, useListOfficeStore } from "./stores";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

export default function Edit() {
  const { id } = useParams();
  const [state, actions] = useDetailStore();
  const [stateOffice, actionsOffice] = useListOfficeStore();

  useEffect(() => {
    document.title = `Detail Shift - Pengaturan`;
    actions.findData(id);
    actionsOffice.loadData();
  }, []);

  const options = [];
  let defaultValue = [];

  stateOffice.data.forEach((item) => {
    options.push({ value: item._id, label: item.name });
  });

  // set selected option
  stateOffice.data.forEach((item) => {
    state.data.offices.forEach((selectedItem) => {
      if (selectedItem === item._id) {
        // compare option with selected _id
        defaultValue.push({ value: item._id, label: item.name });
      }
    });
  });

  let contentUi = <Loader />;

  if (!state.isLoading) {
    contentUi = (
      <React.Fragment>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-2 sm:px-2">
            <h2
              id="applicant-information-title"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Detail Kantor
            </h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Kode</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {state.data.code}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Deskripsi</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {state.data.description}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Lokasi Masuk
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {convertLocation(state.data.checkIn.location)}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Jam</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {convertTime(state.data.checkIn.timeRange[0])} -{" "}
                  {convertTime(state.data.checkIn.timeRange[1])}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Lokasi Keluar
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {state.data.checkOut.location}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Jam</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {convertTime(state.data.checkOut.timeRange[0])} -{" "}
                  {convertTime(state.data.checkOut.timeRange[1])}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Daftar Kantor
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {defaultValue.map((o, idx) => (
                    <span>
                      {o.label}
                      {defaultValue.length - 1 !== idx && `, `}{" "}
                    </span>
                  ))}
                </dd>
              </div>
            </dl>
          </div>
          <div className="bg-gray-50 px-4 py-2 sm:px-2">
            <div className="flex items-center justify-end space-x-4">
              <Link
                to={`/setting/shift`}
                type="button"
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                Kembali
              </Link>
              <Link
                to={`/setting/shift/edit/${state.data._id}`}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Ubah
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (state.error) {
    contentUi = <Redirect to="/setting/office" />;
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Detail Kantor"
        childs={[
          { text: "Settings", link: "" },
          { text: "Kantor", link: "/setting/office" },
          { text: "Detail Kantor", link: "" }
        ]}
      />
      <div className="container mx-auto px-6 min-h-screen">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
