import React, { useEffect, useState } from "react";
import { TopNav, MobileNav, Loader, Breadcrumb } from "../../App/components";
import {
  useListPersonStore,
  useListShiftStore,
  useDetailOrgStore,
} from "./stores";
import { ListTable } from "./components";
import { useParams } from "react-router-dom";

export default function Detail() {
  const { id } = useParams();
  const [keyTable, setKeyTable] = useState(0);
  const [statePerson, actionsStatePerson] = useListPersonStore();
  const [stateShift, actionsStateShift] = useListShiftStore();

  const [stateDetailOrg, actionDetailOrg] = useDetailOrgStore();

  useEffect(() => {
    document.title = `Jadwal & Izin - Pegawai`;
    actionsStatePerson.updateStore({ unitOrgs: [id], limit: 10000 });
    actionsStatePerson.loadData();
    actionsStateShift.loadData();
    actionDetailOrg.findData(id);
  }, []);

  let handleSearch = async (e) => {
    e.preventDefault();
    await actionsStatePerson.loadData();
    actionsStatePerson.updateStore({ search: "" });
  };

  let contentUi = <Loader />;
  if (!statePerson.isLoading) {
    contentUi = (
      <div className="pb-8">
        <div className="w-full pt-4 pb-4">
          <div className="flex">
            <form className="w-full" onSubmit={(e) => handleSearch(e)}>
              <div className="shadow flex">
                <input
                  type="text"
                  placeholder="Cari"
                  className="w-full rounded p-2 border-transparent"
                  value={statePerson.search}
                  onChange={(event) =>
                    actionsStatePerson.updateStore({
                      search: event.target.value,
                    })
                  }
                />
                <button className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
            <div className="shadow flex">
              <button
                className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light"
                title="Perbaharui data"
                onClick={() => setKeyTable(keyTable + 1)}
              >
                <i className="material-icons">refresh</i>
              </button>
            </div>
            <div className="shadow flex">
              <button
                className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light"
                title="Filter data"
              >
                <i className="material-icons">filter_alt</i>
              </button>
            </div>
            <div className="shadow flex">
              <button
                className="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light"
                title="Urutkan data"
              >
                <i className="material-icons">sort</i>
              </button>
            </div>
          </div>
        </div>
        <ListTable
          key={keyTable}
          data={statePerson.data}
          shift={stateShift.data}
          office={id}
        />
      </div>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <Breadcrumb
        title="Jadwal & Izin"
        childs={[
          { text: "Pegawai", link: "" },
          { text: "Jadwal & Izin", link: "/person/schedule" },
          {
            text:
              stateDetailOrg.data ? stateDetailOrg.data.orgName : "",
            link: "",
          },
        ]}
      />
      <div className="container mx-auto px-6 min-h-screen mb-6">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
