import { createStore, createHook } from "react-sweet-state";
import { removeExceptionApi } from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    loading: true,
  },
  // actions that trigger store mutation
  actions: {
    deleteData:
      ({ personId, date }, onSuccess) =>
      async ({ setState, getState }) => {
        setState({ isLoading: true });

        try {
          const resp = await removeExceptionApi({personId, date});

          if (resp.data && resp.data.code === 200) {
            setState({
              isLoading: false,
            });
            if (onSuccess) {
              onSuccess();
            }
            return;
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
          });
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "deleteExceptionService",
});

export const useDeleteExceptionStore = createHook(Store);
