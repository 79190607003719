import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/id";
import { useAddException, useDeleteExceptionStore } from "./../stores";
import { useTypeExceptionStore } from "./../../../App/stores";
import {
  getScheduleApi,
  updateExceptionsApi,
  removeExceptionsApi,
} from "../api";

export function ListIzin(props) {
  const item = props.data;

  const date = props.date;
  const persons = props.persons;
  const [selectedException, setSelectedException] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [stateSchedule, setSchedule] = useState({ data: null });
  const [, actionsStateException] = useAddException();
  const [, actionsStateDeleteException] = useDeleteExceptionStore();
  const [stateTypeException] = useTypeExceptionStore();

  let listPersons = [];
  persons.forEach((i, x) => {
    listPersons.push(i._id);
  });

  const getSchedule = async (personId, date) => {
    const resp = await getScheduleApi({ personId, date });

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }
      setSchedule({ data: resp.data });
      setShowLoader(false);
      setVisible(false);
    }
  };

  const copyException = async (param) => {
    const resp = await updateExceptionsApi(param);

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }
      props.update();
    }
  };

  const removeAllException = async (param) => {
    const resp = await removeExceptionsApi(param);

    if (resp.data && resp.data.code === 200) {
      if (!resp.data) {
        const errMsg = "Data not found";
        toast.error(errMsg);
      }
      props.update();
    }
  };

  useEffect(() => {
    getSchedule(item._id, date);
  }, []);

  const toggleDialog = () => {
    setVisible(!visible);
    setSelectedException("");
  };

  const handleSave = () => {
    setShowLoader(true);
    if (selectedException !== "") {
      actionsStateException.updateForm({
        personId: item._id,
        date: date,
        exception: selectedException,
      });
      actionsStateException.createData(() => {
        toast.success("Berhasil menyimpan data");
        getSchedule(item._id, date);
      });
    }
  };

  const copyAll = (exception, date, personIds) => {
    copyException({ exception, date, personIds });
  };

  const deleteItem = () => {
    setShowLoader(true);
    actionsStateDeleteException.deleteData(
      {
        personId: item._id,
        date: date,
      },
      () => {
        toast.warning("Berhasil menghapus data");
        getSchedule(item._id, date);
      }
    );
  };

  const deleteAll = (date, personIds) => {
    removeAllException({ date, personIds });
  };

  const loader = (
    <div className="flex-none flex items-center justify-center w-full h-ful py-1">
      <svg
        fill="none"
        className="w-6 h-6 animate-spin"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );

  const inputAbsen = (
    <>
      {!showLoader ? (
        <div className=" input-add-jadwal flex">
          <div className="flex-auto">
            <select
              className="form-select block w-full border border-gray-300 mt-1 text-xs"
              onChange={(o) => setSelectedException(o.target.value)}
            >
              <option></option>
              {Object.keys(stateTypeException).map((key, x) => (
                <option key={x} value={key}>
                  {stateTypeException[key]}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-auto mt-1 mr-1 ml-1">
            <button
              className="flex-none flex items-center justify-center w-full h-full text-gray-400 border border-gray-300"
              onClick={handleSave}
            >
              <span className="material-icons-outlined">save</span>
            </button>
          </div>
        </div>
      ) : (
        loader
      )}
    </>
  );
  return (
    <td>
      {stateSchedule.data &&
      stateSchedule.data.data &&
      stateSchedule.data.data.exception ? (
        <>
          {visible ? (
            inputAbsen
          ) : !showLoader ? (
            <div
              className="min-w-full cursor-pointer text-center px-5 py-1 align-middle text-xs"
              title={`${stateSchedule.data.data.personName} pada ${moment(
                stateSchedule.data.data.date,
                "YYYYMMDD"
              ).format("dddd, Do MMMM YYYY")}`}
            >
              <div className="py-1">
                <span
                  className="cursor-pointer material-icons-outlined align-middle text-xs text-gray-300 hover:text-green-600"
                  title="Salin"
                  onClick={() =>
                    copyAll(
                      stateSchedule.data.data.exception,
                      stateSchedule.data.data.date,
                      listPersons
                    )
                  }
                >
                  copy
                </span>
                <span
                  className="cursor-pointer inline-block align-middle mx-2 font-medium"
                  onClick={toggleDialog}
                >
                  <span className="font-medium text-red-600">{stateTypeException[stateSchedule.data.data.exception]}</span>
                </span>
                <span
                  className="cursor-pointer material-icons-outlined align-middle text-xs text-gray-300 hover:text-red-600"
                  title="Hapus ini"
                  onClick={deleteItem}
                >
                  delete
                </span>
                <span
                  className="cursor-pointer material-icons-outlined align-middle text-xs text-gray-300 hover:text-red-600"
                  title="Hapus semua yang sama"
                  onClick={() =>
                    deleteAll(stateSchedule.data.data.date, listPersons)
                  }
                >
                  clear
                </span>
              </div>
            </div>
          ) : (
            loader
          )}
        </>
      ) : (
        <>
          {visible ? (
            inputAbsen
          ) : !showLoader ? (
            <button
              className="min-w-full cursor-pointer bg-red-600 hover:bg-red-500 shadow-xl px-5 py-1 inline-block text-red-100 hover:text-white rounded"
              title={`${item.personName} pada ${moment(date, "YYYYMMDD").format(
                "dddd, Do MMMM YYYY"
              )}`}
              onClick={toggleDialog}
            >
              <span className="material-icons-outlined align-middle text-sm">
                add_box
              </span>
              <span className="align-middle text-xs">Izin</span>
            </button>
          ) : (
            loader
          )}
        </>
      )}
    </td>
  );
}
