import React from "react";
import { useListOrgStore, useRemoveStore, useListStore } from "../stores";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export function ListItem(props) {
  const item = props.dataItem;

  const [stateListOrg] = useListOrgStore();
  const [, actionsRemove] = useRemoveStore();
  const [, actionsList] = useListStore();

  const deleteAction = async function () {
    if (await actionsRemove.deleteData(item._id)) {
      toast.warning("Berhasil hapus data");
      actionsList.loadData();
    }
  };
  const deleteSubmit = async (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Konfirmasi hapus",
      message: `Apakah yakin hapus ${item.name}`,
      buttons: [
        {
          label: "Hapus",
          className: "bg-red-700",
          onClick: () => {
            deleteAction();
          },
        },
        {
          label: "Batal",
          className: "bg-gray-100 text-gray-800",
        },
      ],
    });
  };

  return (
    <div className="bg-white p-4 border-b-2 border-2 mb-2">
      <div className="flex-1 flex flex-col pb-4">
        <Link
          to={`/setting/office/detail/${item._id}`}
          className="text-md font-medium text-blue-500"
        >
          {item.name}
        </Link>
        <div className="mt-2 text-xs">
          {item.unitOrgs.map((o, idx) => (
            <div key={o} className="mt-1">
              {stateListOrg.dataObject[o].orgName}
            </div>
          ))}
        </div>
        <div className="mt-2">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/place/${item.location.coordinates[0]},${item.location.coordinates[1]}`}
            className="flex items-center text-sm text-blue-500"
          >
            <span className="material-icons-outlined">place</span>
            <span className="ml-1">{item.address}</span>
          </a>
        </div>
      </div>
      <div className="flex mt-2">
        <Link
          to={`/setting/office/edit/${item._id}`}
          className="flex items-center text-sm text-blue-700 mr-4"
        >
          <span className="material-icons-outlined">edit</span>
          <span className="ml-1 font-bold">Ubah</span>
        </Link>
        <button
          onClick={deleteSubmit}
          type="button"
          className="flex items-center text-sm text-red-700"
        >
          <span className="material-icons-outlined">clear</span>
          <span className="ml-1 font-bold">Hapus</span>
        </button>
      </div>
    </div>
  );
}
