import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useListStore } from "../stores";

export function ListPaging() {
  const [stateList, actionsList] = useListStore();

  const totalButton = Math.ceil(stateList.total / stateList.limit);
  const currentPage = Math.ceil(stateList.skip / stateList.limit) + 1;

  const changePage = function (toPage) {
    actionsList.updateStore({
      skip: stateList.limit * toPage,
    });
    actionsList.loadData();
  };

  const prevPage = function () {
    if (currentPage > 1) {
      const toPage = currentPage - 2;
      actionsList.updateStore({
        skip: stateList.limit * toPage,
      });
      actionsList.loadData();
    }
  };

  const nextPage = function () {
    if (currentPage < totalButton) {
      actionsList.updateStore({
        skip: stateList.limit * currentPage,
      });
      actionsList.loadData();
    }
  };

  if (stateList.data.length < 1) {
    return <div />;
  }
  const listPage = [];

  let i = 0;
  while (i < totalButton) {
    listPage.push(i + 1);
    i++;
  }

  const pagingListUi = listPage.map((page) => {
    return (
      <button
        key={page}
        onClick={() => {
          changePage(page - 1);
        }}
        aria-current="page"
        className={`${
          currentPage === page
            ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
        } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
      >
        {page}
      </button>
    );
  });

  return (
    <div className="px-4 py-3 flex items-center justify-between sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Menampilkan <span className="font-medium">{stateList.skip}</span>
            <span className="ml-1 mr-1">ke</span>
            <span className="font-medium">
              {stateList.limit + stateList.skip}
            </span>
            <span className="ml-1">dari</span>
            <span className="ml-1 mr-1 font-medium">{stateList.total}</span>
            entri
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={prevPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Sebelumnya</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pagingListUi}
            <button
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Selanjutnya</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
