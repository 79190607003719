import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
// import { Loader } from "../../App/components";
/* import { useListOrgStore } from "./stores"; */
import Page404 from "../../Page404";
import Home from "./Home";
import Detail from "./Detail";

export default function Office() {
  const { path } = useRouteMatch();

  /*   const [stateListOrg, actionListOrg] = useListOrgStore();

  React.useEffect(() => {
    actionListOrg.loadData();
  }, []);

  if (stateListOrg.isLoading) {
    return <Loader />;
  }
 */

  return (
    <Switch>
      <Route exact path={path}>
        <Home />
      </Route>
      <Route path={`${path}/show/:id`}>
        {/* <Calendar /> */}
        <Detail />
      </Route>
      <Route path={`${path}/*`}>
        <Page404 />
      </Route>
    </Switch>
  );
}
