import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    show: false,
    content: "",
    type: "error",
  },
  actions: {
    showNotification:
      ({ content, type }) =>
      async ({ setState, getState }) => {
        setState(content, type);
      },
    close:
      () =>
      async ({ setState, getState }) => {
        setState({ show: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "notification",
});

export const useNotificationStore = createHook(Store);
