import { apiClient } from "../../../services";

export const listPersonApi = function ({ limit, search, unitOrgs }) {
  return apiClient.get(
    `/admin/person/list?limit=${limit}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};

export const listShiftApi = function ({ limit, skip, search }) {
  return apiClient.get(
    `/admin/shift/list?limit=1000&skip=${skip}&search=${search}&disabled=false`
  );
};

export const listOrgApi = function ({ limit, skip, search }) {
  return apiClient.get(
    `/admin/org/main/list?limit=${limit}&skip=${skip}&search=${search}`
  );
};

export const detailOrgApi = function (_id) {
  return apiClient.get(`/admin/org/detail/${_id}`);
};

export const getScheduleApi = function (personId, date ) {
  return apiClient.get(
    `/admin/att-daily/person/detail/${personId}/${date}`
    //`/admin/att-daily/person/detail/cache/${personId}/${date}/data.js`
  );
};

export const listApi = function ({ limit, skip, search, unitOrgs }) {
  return apiClient.get(
    `/admin/office/list?limit=${limit}&skip=${skip}&search=${search}&unitOrgs=${unitOrgs.join(
      ","
    )}`
  );
};
