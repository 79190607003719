import _ from "lodash";
import { createStore, createHook } from "react-sweet-state";
import { allOfficeApi } from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    error: null,
    data: [],
    dataObject: {},
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },

    loadData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null, data: [] });

        try {
          const resp = await allOfficeApi();

          if (resp.data && resp.data.code === 200) {
            const data = resp.data.data;
            const dataObject = {};
            _.each(data, (doc) => {
              dataObject[doc._id] = doc;
            });
            setState({ data: resp.data.data, dataObject: dataObject });
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
          });
        }

        setState({ isLoading: false });
      },
  },
  // optional, mostly used for easy debugging
  name: "listOfficeService",
});

export const useListOfficeStore = createHook(Store);
