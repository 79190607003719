import React from "react";
import { useEditStore, useListOrgStore } from "../stores";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Alert, Breadcrumb } from "../../../App/components";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export function FormBoxEdit() {
  const [stateListOrg] = useListOrgStore();
  const [stateEdit, actionsEdit] = useEditStore();
  const [unitOrgsOptions, setUnitOrgsOptions] = React.useState(
    stateListOrg.data
  );

  const history = useHistory();

  const filterChangeUnitOrgOptions = (event) => {
    setUnitOrgsOptions(filterBy(stateListOrg.data, event.filter));
  };

  const handleSubmit = async function (e) {
    e.preventDefault();
    if (await actionsEdit.updateData()) {
      toast.success("Berhasil edit data");
      history.push("/setting/office");
    }
  };

  let errorUi;

  if (stateEdit.error) {
    errorUi = (
      <div className="mt-5">
        <Alert type="error" body={stateEdit.error} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb
        title="Ubah Kantor"
        childs={[
          { text: "Pengaturan", link: "" },
          { text: "Kantor", link: "/setting/office" },
          { text: "Ubah Kantor", link: "" },
        ]}
      />
      <form
        className="mb-2 container px-6 mx-auto items-start lg:items-center justify-between"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Nama Kantor
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Kantor"
              value={stateEdit.name}
              onChange={(event) =>
                actionsEdit.updateState({ name: event.target.value })
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Alamat
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Alamat"
              value={stateEdit.address}
              onChange={(event) =>
                actionsEdit.updateState({ address: event.target.value })
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Latitude
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-latitude"
              type="number"
              placeholder="0.0"
              value={stateEdit.location.coordinates[0]}
              onChange={(event) =>
                actionsEdit.updateState({
                  location: {
                    type: "Point",
                    coordinates: [
                      parseFloat(event.target.value),
                      stateEdit.location.coordinates[1],
                    ],
                  },
                })
              }
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Longitude
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="number"
              placeholder="0.0"
              value={stateEdit.location.coordinates[1]}
              onChange={(event) =>
                actionsEdit.updateState({
                  location: {
                    type: "Point",
                    coordinates: [
                      stateEdit.location.coordinates[0],
                      parseFloat(event.target.value),
                    ],
                  },
                })
              }
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Unit Organisasi
            </label>
            <MultiSelect
              filterable={true}
              onFilterChange={filterChangeUnitOrgOptions}
              data={unitOrgsOptions}
              onChange={(e) => {
                actionsEdit.updateState({
                  unitOrgs: e.value.map((o) => {
                    return o._id;
                  }),
                });
              }}
              value={stateEdit.unitOrgs.map((item) => {
                return stateListOrg.dataObject[item];
              })}
              textField="orgName"
              dataItemKey="_id"
            />
          </div>
        </div>
        {errorUi}
        <div className="bg-gray-50 px-4 py-2 sm:px-2">
          <div className="flex items-center justify-end space-x-4">
            <Link
              to={`/setting/office`}
              type="button"
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              Kembali
            </Link>
            <button
              type="submit"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Simpan
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
