import { createStore, createHook } from "react-sweet-state";

const initState = {
    timeoff: "Cuti",
    course: "Diklat",
    sick: "Sakit",
    study: "Tugas Belajar",
    outcity: "Tugas Luar"
};

const Store = createStore({
    // value of the store on initialisation
    initialState: {
        ...initState,
    },
    // actions that trigger store mutation
    actions: {

    },
    // optional, mostly used for easy debugging
    name: "TypeExceptionService",
});

export const useTypeExceptionStore = createHook(Store);
