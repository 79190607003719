import { createStore, createHook } from "react-sweet-state";
import Joi from "joi";
import { addApi } from "../api";

const locationTypeRules = Joi.object({
  type: Joi.string().valid("Point").required(),
  coordinates: Joi.array().items(Joi.number().min(0)).length(2).required(),
});

const bodyRules = Joi.object({
  name: Joi.string().required(),
  address: Joi.string(),
  location: locationTypeRules,
  unitOrgs: Joi.array().items(Joi.string()),
});

const initState = {
  isLoading: false,
  error: null,
  name: "",
  address: "",
  location: {
    type: "Point",
    coordinates: [0.0, 0.0],
  },
  unitOrgs: [],
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    updateState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    createData:
      () =>
      async ({ setState, getState, actions }) => {
        setState({ isLoading: true, error: null });

        let bodyForm = { ...getState() };

        //validate and sanitize
        try {
          bodyForm = await bodyRules.validateAsync(bodyForm, {
            stripUnknown: true,
          });
        } catch (err) {
          setState({
            error: err.details[0].message,
            isLoading: false,
          });
          return false;
        }

        try {
          await addApi(bodyForm);
          actions.resetState();
          return true;
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "createSettingOfficeService",
});

export const useCreateStore = createHook(Store);
