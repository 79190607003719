import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page404 from "../Page404";
import Office from "./Office";
import Shift from "./Shift";

export default function Setting() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/office`}>
          <Office />
        </Route>
        <Route path={`${path}/shift`}>
          <Shift />
        </Route>
        <Route path={`${path}/*`}>
          <Page404 />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
