import { createStore, createHook } from "react-sweet-state";
import { allOrgApi } from "../api";

const initState = {
  isLoading: false,
  error: null,
  data: [],
  dataObject: {},
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },

    loadData:
      () =>
      async ({ setState }) => {
        setState({ isLoading: true, error: null });

        try {
          const resp = await allOrgApi();

          if (resp.data && resp.data.code === 200) {
            const data = [...resp.data.data];
            const dataObject = {};
            data.forEach((item) => {
              dataObject[item._id] = item;
            });

            setState({ data, dataObject, isLoading: false });
            return true;
          }
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "listOrgService",
});

export const useListOrgStore = createHook(Store);
