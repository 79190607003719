import { createStore, createHook } from "react-sweet-state";
import { addOfficePersonApi } from "../api";

const initState = {
  isLoading: false,
  error: null,
  officeId: "",
  personId: "",
};

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    ...initState,
  },
  // actions that trigger store mutation
  actions: {
    resetState:
      () =>
      async ({ setState, getState }) => {
        setState(initState);
      },
    updateState:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    createData:
      () =>
      async ({ setState, getState, actions }) => {
        setState({ isLoading: true, error: null });

        let bodyForm = { ...getState() };

        try {
          await addOfficePersonApi(bodyForm);
          actions.resetState();
          return true;
        } catch (err) {
          setState({
            error: err && err.response ? err.response.data : "Unknown error",
            isLoading: false,
          });
          return false;
        }
      },
  },
  // optional, mostly used for easy debugging
  name: "createSettingOfficeService",
});

export const useCreateStore = createHook(Store);
