import axios from "axios";

const baseApi = process.env.REACT_APP_BASE_API;

const instance = axios.create({
  baseURL: baseApi,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  },
});

export const apiClient = instance;
