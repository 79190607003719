import moment from "moment";
import "moment/locale/id";

export const convertTime = (time) => {
  let result = "";
  time = time.toString();
  if (time.length === 3) {
    time = "0" + time;
  }
  time = time.split("");
  for (let i = 0; i < time.length; i++) {
    if (i === 2) {
      result = result + ":";
      result = result + time[i];
    } else {
      result = result + time[i];
    }
  }
  return result;
};

export const convertLocation = (loc) => {
  return loc.replace("Office", "KANTOR").replace("Anywhere", "RUMAH");
};

export const today = moment().format("YYYYMMDD");

export const getMonday = (date) => {
  return moment(date, "YYYYMMDD").startOf("week").day("Senin");
};
export const getSunday = (date) => {
  return moment(date, "YYYYMMDD").startOf("week").day("Minggu");
};
export const getFirstDay = (date) => {
  return moment(date, "YYYYMMDD").startOf("month");
};
export const getLastDay = (date) => {
  return moment(date, "YYYYMMDD").endOf("month");
};

export const addDay = (date, day) => {
  return moment(date, "YYYYMMDD").add(day, "d");
};

export const addMonth = (date, month) => {
  return moment(date, "YYYYMMDD").add(month, "month");
};

export const subDay = (date, day) => {
  return moment(date, "YYYYMMDD").subtract(day, "d");
};

export const subMonth = (date, month) => {
  return moment(date, "YYYYMMDD").subtract(month, "month");
};
