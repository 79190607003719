import { createStore, createHook } from "react-sweet-state";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    showMobileMenu: false,
    showMenuTopAvatar: false,
  },
  actions: {
    setShowMobileMenu:
      (newVal) =>
      async ({ setState, getState }) => {
        setState({ showMobileMenu: !!newVal });
      },
    setShowMenuTopAvatar:
      (newVal) =>
      async ({ setState, getState }) => {
        setState({ showMenuTopAvatar: !!newVal });
      },
  },
  // optional, mostly used for easy debugging
  name: "layout",
});

export const useLayout = createHook(Store);
