import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { initAuth, useAuthStore } from "./stores";
import { Loader } from "./components";

import Page404 from "../Page404";
import Auth from "../Auth";
import Home from "../Home";
import Person from "../Person";
import Org from "../Org";
import Report from "../Report";
import Setting from "../Setting";
import Test from "../Test";

function App() {
  const [stateAuth, actionsAuth] = useAuthStore();

  useEffect(() => {
    initAuth({ stateAuth, actionsAuth });
  }, []);

  let contentUi = <Loader />;

  if (stateAuth.initAuth) {
    if (stateAuth.isAuth) {
      contentUi = (
        <Router>
          <Switch>
            Kantor
            <Route path="/home" component={Home} />
            <Route path="/person" component={Person} />
            <Route path="/org" component={Org} />
            <Route path="/report" component={Report} />
            <Route path="/setting" component={Setting} />
            <Route path="/test" component={Test} />
            <Route exact path="/">
              <Redirect
                to={{
                  pathname: "/home",
                }}
              />
            </Route>
            <Route path="*" component={Page404} />
          </Switch>
        </Router>
      );
    } else {
      contentUi = (
        <Router>
          <Switch>
            <Route path="/" component={Auth} />
          </Switch>
        </Router>
      );
    }
  }

  let loadingUi;
  if (stateAuth.isLoading) {
    loadingUi = <Loader />;
  }

  return (
    <div className="App bg-gray-50">
      {loadingUi}
      <ToastContainer />
      {contentUi}
    </div>
  );
}

export default App;
