import React, { useEffect } from "react";
import { TopNav, MobileNav, Loader } from "../../App/components";
import { FormBoxAdd } from "./components";
import { useCreateStore } from "./stores";

export default function Add() {
  const [state] = useCreateStore();

  useEffect(() => {
    document.title = `Tambah Kantor - Pengaturan`;
  }, []);

  let contentUi = <Loader />;
  if (!state.isLoading) {
    contentUi = (
      <React.Fragment>
        <FormBoxAdd />
      </React.Fragment>
    );
  }

  return (
    <>
      <MobileNav />
      <TopNav />
      <div className="container mx-auto px-6 min-h-screen">
        <div className="w-full h-64 rounded">{contentUi}</div>
      </div>
    </>
  );
}
